import moment from "moment";

export const LAST_YEAR_MONTHS = (() => {
  const months = [];
  const startDate = moment("2023-01-01").startOf("month");
  const endDate = moment().startOf("month");

  let currentMonth = startDate.clone();

  while (currentMonth.isSameOrBefore(endDate)) {
    months.push({
      value: currentMonth.clone(),
      label: currentMonth.format("MMMM YYYY"),
    });
    currentMonth.add(1, "months");
  }

  return months.reverse();
})();
