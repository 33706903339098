import { getCompanies } from '@/API/repositories/company';
import { getValidInvoicesReport } from '@/API/repositories/reports';
import { getPdfsByIds } from '@/API/repositories/storedDocument';
import { Colors } from '@/common/colors/colors';
import Card from '@/common/components/Card';
import ExportExcel from '@/common/components/ExportExcel';
import Input from '@/common/components/Input';
import Loading from '@/common/components/Loading';
import SelectInput from '@/common/components/SelectInput';
import ToggleSwitch from '@/common/components/ToggleSwitch';
import ActionButton from '@/common/components/buttons/ActionButton';
import {
	formatDate,
	formatToDateTamplate,
} from '@/common/functions/dateFormater';
import { filterEntitiesByIds } from '@/common/functions/filterEntitiesByIds';
import { findDefaultMarketFromOptions } from '@/common/functions/findDefaultMarketFromOptions';
import { findPossibleProductFieldByMarket } from '@/common/functions/findPossibleProductFieldByMarket';
import { handleMapToOptions } from '@/common/functions/handleMapToOptions';
import { saveAs } from '@/common/functions/saveAs';
import { sortTicketsByInvoiceNumber } from '@/common/functions/sortTicketsByInvoiceNumber';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import { useEffect, useMemo, useRef, useState } from 'react';
import { findCompanyNameById } from '../../../../../common/functions/findCompanyNameById';
import { Flex, RightFlex } from '../../ValidInvoicesReport.styled';
import { getMarketById } from '../../helpers/getMarketById';
import { Icon } from './ValidInvoicesReportSearchBar.styled';
import { getExcelData } from './helpers/getExcelData';
const JSZip = require('jszip');
const zip = new JSZip();

const ValidInvoicesReportSearchBar = ({
	tickets,
	setTickets,
	visibleTickets,
	setVisibleTickets,
}) => {
	const [selectedMarket, setSelectedMarket] = useState();
	const [companies, setCompanies] = useState();
	const [companiesOptions, setCompaniesOptions] = useState();
	const [selectedCompany, setSelectedCompany] = useState();

	const {
		options: { marketsOptions, queuesOptions },
		commonData: { markets, products },
	} = useCommonDataContext();
	const { addMessage } = useMessageQueueContext();
	const { makeRequest, hasUnfilledRequest } = useRequestsContext();

	const fromDateRef = useRef();
	const toDateRef = useRef();
	const salesRef = useRef();
	const correctionRef = useRef();

	const handleSearch = async () => {
		if (!selectedMarket?.value) {
			return addMessage('Select market', 'error');
		}

		if (!fromDateRef.current?.value || !toDateRef.current?.value) {
			return addMessage('Select all dates', 'error');
		}

		const payload = {};

		payload.market = selectedMarket.value;
		payload.company = selectedCompany.value;
		payload.fromDate = new Date(fromDateRef.current.value);
		payload.toDate = new Date(toDateRef.current.value);

		const response = await makeRequest(
			getValidInvoicesReport.bind(null, payload)
		);

		if (!response.data) {
			return addMessage('Something went wrong', 'error');
		}

		if (response.data) {
			setTickets(() => response.data);
		}
	};

	const handleGetAllMonthFiles = async () => {
		const ids = visibleTickets.map(
			(ticket) => ticket.valid_invoice.stored_document
		);

		const query = {};

		query.ids = ids;

		const response = await makeRequest(getPdfsByIds.bind(null, query));
		if (response.data) {
			const files = response.data;
			files.forEach((file) => {
				file.name = file.name.split('_')[1] || file.name;
			});

			files.forEach((file) => {
				zip.file(file.name, file.data.data);
			});

			zip.generateAsync({ type: 'blob' }).then((content) => {
				saveAs(
					content,
					`${findCompanyNameById(
						companies,
						selectedCompany.value
					)}_invoices_${getMarketById(
						selectedMarket.value,
						markets
					)?.short.toUpperCase()}_${formatToDateTamplate(
						fromDateRef.current?.value
					)}_${formatToDateTamplate(toDateRef.current?.value)}.zip`
				);
			});

			files.forEach((file) => {
				zip.remove(file.name);
			});
		}
	};

	const hansleSetVisibleTickets = () => {
		const corrections =
			tickets?.filter(
				(ticket) => ticket.valid_invoice.invoice_type === 'correction'
			) || [];
		const sales =
			tickets?.filter(
				(ticket) => ticket.valid_invoice.invoice_type === 'sales'
			) || [];

		setVisibleTickets(() => {
			const visible = [];

			if (salesRef.current?.checked) {
				visible.push(...sales);
			}

			if (correctionRef.current?.checked) {
				visible.push(...corrections);
			}

			return sortTicketsByInvoiceNumber(visible);
		});
	};

	const excelData = useMemo(() => {
		if (!!visibleTickets?.length) {
			const currency = visibleTickets[0]._order.currency;

			return getExcelData(visibleTickets, markets, currency);
		}
	}, [visibleTickets]);

	const loadData = async () => {
		const response = await makeRequest(getCompanies);

		if (response?.data) {
			setCompanies(() => response.data);
		}
	};

	const handleSetMarket = (market) => {
		const currentMarket =
			market || findDefaultMarketFromOptions(queuesOptions, marketsOptions);

		setSelectedMarket(currentMarket);
		handleMarketChange(currentMarket);
	};

  const handleMarketChange = (market) => {
    setSelectedCompany(() => null);
    setCompaniesOptions(() => null);

		if (market && companies) {
			const possibleCompanies = findPossibleProductFieldByMarket(
				queuesOptions,
				market.value,
				products,
				'company'
			);

			const uniqueCompaniesIds = Array.from(new Set(possibleCompanies));

			const filteredCompanies = filterEntitiesByIds(
				companies,
				uniqueCompaniesIds
			);

			const newCompaniesOptions = handleMapToOptions(
				filteredCompanies,
				'name',
				'_id'
			);

			setCompaniesOptions(newCompaniesOptions);
			const company = newCompaniesOptions[0];

			handleSetCompany(company, market);
		}
	};

	const handleSetCompany = (company, market = selectedMarket) => {
		setSelectedCompany(company);
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		handleSetMarket();
	}, [queuesOptions, marketsOptions, products, companies]);

	useEffect(() => {
		hansleSetVisibleTickets();
	}, [tickets]);

	return (
		<Card>
			{(hasUnfilledRequest(
				getValidInvoicesReport,
				getPdfsByIds,
				getCompanies
			) ||
				!marketsOptions?.length) && <Loading />}
			<Flex>
				<div>
					<SelectInput
						name='Market'
						placeholder='Select market...'
						selectWidth={250}
						width={100}
						options={marketsOptions}
						setSelected={handleSetMarket}
						selected={selectedMarket}
						color={Colors.darkBlue}
					/>
					<SelectInput
						name='Company'
						placeholder='Select company...'
						selectWidth={250}
						width={100}
						options={companiesOptions}
						setSelected={handleSetCompany}
						selected={selectedCompany}
						color={Colors.darkBlue}
					/>
				</div>
				<div>
					<Input
						inputWidth={250}
						width={100}
						inputRef={fromDateRef}
						name='From'
						type='date'
						color={Colors.darkBlue}
					/>
					<Input
						inputWidth={250}
						inputRef={toDateRef}
						width={100}
						name='To'
						type='date'
						color={Colors.darkBlue}
					/>
					<RightFlex>
						<ToggleSwitch
							onChange={() => hansleSetVisibleTickets()}
							toggleRef={salesRef}
							text='Sales'
							width={130}
							gap='12px'
							checked
						/>
						<ToggleSwitch
							onChange={() => hansleSetVisibleTickets()}
							toggleRef={correctionRef}
							text='Correction'
							width={110}
							gap='12px'
							checked
						/>
					</RightFlex>
				</div>
			</Flex>
			<RightFlex>
				{!!visibleTickets?.length && (
					<>
						<div>
							<Icon
								onClick={() => handleGetAllMonthFiles()}
								className='fa fa-file-zipper animation-scale'
							/>
						</div>
						<ExportExcel
							excelData={excelData}
							fileName={`${findCompanyNameById(
								companies,
								selectedCompany.value
							)}_${getMarketById(
								selectedMarket.value,
								markets
							).short.toUpperCase()}_Faktury_${formatDate(
								fromDateRef.current?.value,
								'DD.MM.YYYY'
							)}-${formatDate(toDateRef.current?.value, 'DD.MM.YYYY')}.csv`}
						/>
					</>
				)}
				<ActionButton onClick={() => handleSearch()} defaultText='Search' />
			</RightFlex>
		</Card>
	);
};

export default ValidInvoicesReportSearchBar;
