import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getConsultantTicketsAll } from "@/API/repositories/tickets";
import { getCallingDayConsultantStatistics } from "@/API/repositories/calling";
import { VonageClient, ConfigRegion, LoggingLevel } from "@vonage/client-sdk";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { Colors } from "@/common/colors/colors";
import { useRequestsContext } from "@/common/hooks/requestHook";
import userManager from "@/API/userManager";
import { getUserWorkingInformation } from "@/API/repositories/voip";
import { getConsultantBlackboardInfo } from "@/API/repositories/blackboardInfo";
import DayInfomration from "./calling/DayInfomration";
import SearchBar from "./calling-vonage/search/SearchBar";
import ConsultantStatusButtons from "./calling-vonage/ConsultantStatusButtons";
import GeneralStatistics from "./calling-vonage/GeneralStatistics";
import FillLoading from "@/common/components/FillLoading";
import DetailedStatistics from "./calling-vonage/DetailedStatistics";
import SearchingContact from "./calling-vonage/SearchingContact";
import OutboundView from "./calling-vonage/OutboundView";
import ConsultantDecisionCalling from "./calling-vonage/ConsultantDecisionCalling";
import ContactInbound from "./calling-vonage/inbound/ContactInbound";
import WaitingPopup from "./calling-vonage/WaitingPopup";
import PopUp from "@/common/components/PopUp";
import Conecting from "./calling-vonage/Conecting";
import VoipError from "./calling-vonage/VoipError";
import TicketView from "./infobar/ticket/components/ticketView/TicketView";
import { sortTickets } from "./infobar/ticket/helpers/sortTickets";
import { getAmountOfNotSeenMessages } from "./infobar/ticket/helpers/getAmountOfNotSeenMessages";
import Blackboard from "./dashboard/panel/blackboard-info/Blackboard";

const ErrorText = styled.h3`
  color: ${Colors.red};
  text-align: center;
`;

const Container = styled.div`
  min-width: 1040px;
  max-width: 1040px;
  height: fit-content;
  background-color: white;
  padding: 30px;
  border-radius: 15px;
`;

const Wrapper = styled.div`
  background-color: ${Colors.lightlightBlue};
  width: 100vw;
  padding-top: 30px;
  height: 100vh;
  overflow-y: scroll;
`;

const Flex = styled.div`
  display: flex;
  gap: 40px;
`;

const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
`;

const DailyInformation = styled.div`
  margin: 40px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;

// const TOKEN_EXPIRED_TIME = 1000 * 60 * 5;

const ConsultantCallingVonage = () => {
  const [isPlivoConnected, setIsPlivoConnected] = useState(false);
  const [device, setDevice] = useState(null);
  const [call, setCall] = useState(null);
  const [session, setSession] = useState();

  const naviagte = useNavigate();
  const [hasMediaDevices, setMediaDevices] = useState(false);

  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((result) => {
      setMediaDevices(true);
    })
    .catch((e) => setMediaDevices(false));

  const [data, setData] = useState();
  const [workingTime, setWorkingTime] = useState(false);
  const [queuesWorkingSeconds, setQueuesWorkingSeconds] = useState();
  const [currentQueue, setCurrentQueue] = useState();
  const [breakSeconds, setBreakSeconds] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [oldTickets, setOldTickets] = useState([]);
  const [openTickets, setOpenTickets] = useState(false);
  const [blackboardInfo, setBlackboadrInfo] = useState();

  const { makeRequest } = useRequestsContext();
  const {
    sendStartCalling,
    currentContactOutbound,
    callingClientCredentials,
    setShowCallingDescription,
    showCallingDescription,
    waiting,
    sendLoginInformation,
    setWaiting,
    sendArchive,
    setCallingClientCredentials,
    setCurrentContactOutbound,
    isConnected,
    takeABreak,
    token,
    sendGetToken,
    voipError,
    setVoipError,
    setIsTalkingTicket,
    onUnmount,
    setHasPosibilityToCallNext,
    incoming,
    startRef,
    breakRef,
    isTalking,
    countWrapTime,
    setCountWrapTime,
    endCall,
  } = useContext(CallCRMApiContextVonage);

  const currentUserId = userManager.getUser().id;

  useEffect(() => onUnmount, []);

  useEffect(() => {
    if (device) return;
    try {
      const client = new VonageClient({
        loggingLevel: LoggingLevel.Verbose,
        region: ConfigRegion.EU,
      });

      setDevice(() => client);
    } catch (e) {}
  }, [device]);

  useEffect(() => {
    if (!device && !token) return;

    device
      .createSession(token)
      .then((session) => {
        setSession(session);
        setIsPlivoConnected(() => true);
      })
      .catch((error) => console.log(error));
  }, [token]);

  useEffect(() => {
    if (!device) return;

    device.on("error", (twilioError, call) => {
      setIsPlivoConnected(() => false);
    });

    device.on("callInvite", (callId, from, channelTyp) => {
      device
        .answer(callId)
        .then(() => {
          setCall(() => callId);
          console.log("Success answering call.");
        })
        .catch((error) => {
          console.error("Error answering call: ", error);
        });
    });

    device.on("tokenWillExpire", () => {
      sendGetToken();
    });
  }, [device]);

  const handleWrappTimeClear = () => {
    sendArchive();
    setWorkingTime(() => false);
    setCountWrapTime(() => false);
    setCallingClientCredentials(() => null);
    setCurrentContactOutbound(() => null);

    breakRef.current = true;
    startRef.current = false;
  };

  // useEffect(() => {
  // const interval = setInterval(() => {
  // sendGetToken();
  // }, TOKEN_EXPIRED_TIME);
  // return () => clearInterval(interval);
  // }, []);

  const handleBreak = (e) => {
    e && e.preventDefault();
    setWorkingTime(() => false);
    takeABreak();
    setHasPosibilityToCallNext(() => false);

    breakRef.current = true;
    startRef.current = false;
  };

  const handleStart = (e) => {
    e && e.preventDefault();
    setWorkingTime(() => true);
    sendStartCalling();
    setWaiting(() => true);

    startRef.current = true;
    breakRef.current = false;
  };
  const handleEnd = (e) => {
    e.preventDefault();
    setWorkingTime(() => false);
    startRef.current = false;
    breakRef.current = false;

    naviagte("/consultant/dashboard");
  };

  const handleChangeQueue = (queueName) => {
    setCurrentQueue(() => queueName);
  };

  const incrementCurrentQueueTimer = (timersState) => {
    if (!currentQueue) return;

    const result = [...timersState];
    const queueItem = result.find((item) => item.queue === currentQueue);

    if (!queueItem) {
      result.push({ queue: currentQueue, seconds: 1 });
    } else {
      queueItem.seconds += 1;
    }

    return result;
  };

  const handleInterval = () => {
    return setInterval(async () => {
      if (startRef.current) {
        setQueuesWorkingSeconds((prev) => incrementCurrentQueueTimer(prev));
      }
      if (breakRef.current) {
        setBreakSeconds((prev) => prev + 1);
      }
    }, [1000]);
  };

  const loadData = async () => {
    const workingResponse = await makeRequest(getUserWorkingInformation);

    if (!workingResponse.data) return;

    setCurrentQueue(() => workingResponse.data.last_queue);
    setBreakSeconds(() => workingResponse.data.break_seconds);
    setQueuesWorkingSeconds(() => workingResponse.data.queues_working_seconds);
  };

  const loadTickets = async () => {
    const responseStats = await makeRequest(getCallingDayConsultantStatistics);
    if (responseStats.data) {
      setData(() => responseStats.data);
    }

    const response = await makeRequest(getConsultantTicketsAll);

    if (response.data?.activeTickets) {
      const { activeTickets } = response.data;
      const sortedActiveTickets = sortTickets(activeTickets);

      setTickets(() => sortedActiveTickets);

      if (tickets) {
        const previous = getAmountOfNotSeenMessages(tickets, currentUserId);
        const current = getAmountOfNotSeenMessages(
          activeTickets,
          currentUserId
        );

        if (previous < current) {
          addMessage("You have some new messages", "error");
        }
      }
    }

    if (response.data?.outdatedTickets) {
      const { outdatedTickets } = response.data;
      const sortedOutdatedTickets = sortTickets(outdatedTickets);

      setOldTickets(() => sortedOutdatedTickets);
    }
  };

  const handleEndCall = async () => {
    setIsTalkingTicket(() => false);
    endCall(call);
  };

  const handleTickets = (e) => {
    e.preventDefault();
    setOpenTickets(() => true);
  };

  const handleLoadBlackboardInfo = async () => {
    const response = await makeRequest(getConsultantBlackboardInfo);

    if (response.data) {
      const now = new Date().getTime();
      const newInfos = response.data.filter(
        (infos) => now - new Date(infos.created_at).getTime() < 20000
      );

      newInfos.forEach((info) => {
        const text =
          info.translations.find(
            (translation) => translation.lang === userManager.getUser().lang
          )?.text || info.message;

        addMessage(text, "info", 20000);
      });

      setBlackboadrInfo(() => response.data);
    }
  };

  useEffect(() => {
    loadTickets();
    handleLoadBlackboardInfo();
    const interval1 = setInterval(() => {
      loadTickets();
    }, 20000);

    const interval2 = setInterval(() => {
      handleLoadBlackboardInfo();
    }, 8000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  useEffect(() => {
    const interval = handleInterval();
    return () => {
      clearInterval(interval);
    };
  }, [currentQueue]);

  useEffect(() => {
    loadData();
  }, [showCallingDescription]);

  useEffect(() => {
    sendLoginInformation();
  }, []);

  const { translation } = useTranslationContext();
  const { addMessage } = useMessageQueueContext();

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <Wrapper>
      <TopInfo>
        <DayInfomration
          queuesWorkingSeconds={queuesWorkingSeconds}
          breakSeconds={breakSeconds}
        />
        <Flex>
          <SearchBar handleEndCall={handleEndCall} />
          <ConsultantStatusButtons
            handleEnd={handleEnd}
            handleBreak={handleBreak}
            handleStart={handleStart}
            handleTickets={handleTickets}
            ticketsNumber={getAmountOfNotSeenMessages(tickets, currentUserId)}
            onQueueChange={handleChangeQueue}
          />
        </Flex>
      </TopInfo>
      <DailyInformation>
        {data && translation ? (
          <GeneralStatistics data={data} />
        ) : (
          <div style={{ maxWidth: "1000px", height: "200px" }}>
            <FillLoading />
          </div>
        )}
        <Container>
          {blackboardInfo && <Blackboard data={blackboardInfo} />}
        </Container>

        {data ? (
          <DetailedStatistics
            contactsData={[
              ...data.getTodayOutboundContacts,
              ...data.getTodayInboundContacts,
            ]}
            mailsData={data.findTodayMails}
          />
        ) : (
          <div
            style={{ minWidth: "1000px", maxWidth: "1000px", height: "500px" }}
          >
            <FillLoading />
          </div>
        )}
      </DailyInformation>
      {workingTime && callingClientCredentials && <SearchingContact />}
      {currentContactOutbound && (
        <OutboundView
          handleEndCall={handleEndCall}
          isTalking={isTalking}
          countWrapTime={countWrapTime}
          setCountWrapTime={setCountWrapTime}
          handleWrappTimeClear={handleWrappTimeClear}
        />
      )}
      {incoming && (
        <ContactInbound
          incoming={incoming}
          isTalking={isTalking}
          countWrapTime={countWrapTime}
          setCountWrapTime={setCountWrapTime}
          handleWrappTimeClear={handleWrappTimeClear}
          handleEndCall={handleEndCall}
        />
      )}
      {showCallingDescription && (
        <ConsultantDecisionCalling
          setShowCalling={setShowCallingDescription}
          handleBreak={handleBreak}
        />
      )}
      {waiting && <WaitingPopup />}
      {!hasMediaDevices && (
        <PopUp>
          <ErrorText>{translation.errorText}</ErrorText>
        </PopUp>
      )}
      {(!isConnected || !isPlivoConnected) && (
        <Conecting
          isConnected={isConnected}
          isPlivoConnected={isPlivoConnected}
        />
      )}
      {voipError && <VoipError close={setVoipError} />}
      {openTickets && (
        <TicketView
          isCallingTicket={true}
          handleEndCall={handleEndCall}
          setIsTicketViewOpen={setOpenTickets}
          tickets={tickets}
          oldTickets={oldTickets}
          loadData={loadTickets}
        />
      )}
    </Wrapper>
  );
};

export default ConsultantCallingVonage;
