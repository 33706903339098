import React from 'react';
import { getOrderWithFilter } from '../../../API/repositories/order';
import Card from '../../../common/components/Card';
import Loading from '../../../common/components/Loading';
import { getCustomStyles } from './helpers/getCustomStyles';
import { Wrapper } from './OrderFilterTable.styled';
import Table from '@/common/components/Table';
import FilterOptionsModal from './components/orderFilter/FilterOptionsModal';
import FilterTable from './components/filterTable/FilterTable';
import useOrderFilterTable from './useOrderFilterTable';
import SearchBox from './components/searchBox/SearchBox';

const OrderFilterTable = () => {
	const {
		isFiltersModalOpen,
		setIsFiltersModalOpen,
		selectedData,
		tableRows,
		groupedByTableData,
		hasUnfilledRequest,
	} = useOrderFilterTable();

	return (
		<Wrapper>
			<Card>
				<SearchBox
					getCustomStyles={getCustomStyles}
					setIsFiltersModalOpen={setIsFiltersModalOpen}
				/>
			</Card>
			{isFiltersModalOpen && (
				<FilterOptionsModal setIsFiltersModalOpen={setIsFiltersModalOpen} />
			)}
			{tableRows && !groupedByTableData && (
				<FilterTable rows={tableRows} columns={selectedData.displayFields} />
			)}
			{groupedByTableData && (
				<Table
					className='styled-table'
					headers={groupedByTableData.headers}
					raws={groupedByTableData.orders}
				/>
			)}
			{hasUnfilledRequest(getOrderWithFilter) && <Loading />}
		</Wrapper>
	);
};

export default OrderFilterTable;
