import ActionButton from '@/common/components/buttons/ActionButton';
import { Wrapper } from './FilterTable.styled';
import useFilterTable from './useFilterTable';

const FilterTable = ({ rows, columns, howManyToShow = 50 }) => {
	const { handleSort, sortInfo, currentRows, changePage, page, handleSetPage } =
		useFilterTable(rows, columns, howManyToShow);
	return (
		<Wrapper>
			{rows ? (
				<div className='styled-table-2 tableWrap'>
					<div>
						<div
							className='d-flex align-items-center'
							style={{ gap: '20px', margin: '20px 0' }}
						>
							<h5>
								<strong>Total:</strong> {rows.length}
							</h5>
						</div>
					</div>
					<table>
						<thead>
							<tr>
								{columns
									.map((element) => element.label)
									.map((column, i) => {
										return (
											<th
												key={column}
												onClick={(e) => handleSort(e, i)}
												style={{
													cursor: 'pointer',
												}}
											>
												{column}
												{sortInfo.index === i ? (
													sortInfo.isAscending ? (
														<i
															className='fa fa-sort-up'
															style={{ fontSize: '20px', marginLeft: '5px' }}
														/>
													) : (
														<i
															className='fa fa-sort-down'
															style={{ fontSize: '20px', marginLeft: '5px' }}
														/>
													)
												) : (
													''
												)}
											</th>
										);
									})}
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{currentRows.map((row, i) => {
								return (
									<tr key={i}>
										{row.data.map((element, i) => (
											<td key={Math.random() + '-' + element + '-' + i}>
												{element ? element : 'brak'}
											</td>
										))}
										<td>
											<i
												className='fa fa-edit'
												style={{
													fontSize: '14px',
													color: 'black',
													cursor: 'pointer',
												}}
												onClick={() => {
													window
														.open(`/dashboard/order/${row._id}`, '_blank')
														.focus();
												}}
											></i>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div
						className='d-flex align-items-center justify-content-between'
						style={{ marginTop: '30px' }}
					>
						<div className='d-flex align-items-center' style={{ gap: '20px' }}>
							<h5>
								<strong>Total:</strong> {rows.length}
							</h5>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
							<ActionButton
								onClick={(e) => changePage(e, -1)}
								defaultText='<'
								disabled={page <= 1}
								style={{ padding: '8px 16px' }}
							/>
							<input
								style={{ width: '60px', height: '40px' }}
								type='number'
								value={page}
								onChange={(e) => handleSetPage(e)}
							/>
							<ActionButton
								onClick={(e) => changePage(e, 1)}
								defaultText='>'
								disabled={page > Math.floor(rows.length / howManyToShow)}
								style={{ padding: '8px 16px' }}
							/>
						</div>
					</div>
				</div>
			) : (
				' '
			)}
		</Wrapper>
	);
};

export default FilterTable;
