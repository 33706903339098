import { getFiltersForUser } from '@/API/repositories/filters';
import { getAllPosibleFiledsToFilter } from '@/API/repositories/order';
import { useRequestsContext } from '@/common/hooks/requestHook';
import {
	createContext,
	useContext,
	useEffect,
	useState,
	useReducer,
} from 'react';
import { operationsReducer } from '../helpers/operationsReducer';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { reducer } from '../helpers/reducer';
import { defaultFilters, defaultSelectedData } from '../constants/default';

export const OrderFilterTableContext = createContext({});

export function OrderFilterTableProvider({ children }) {
	const { hasUnfilledRequest, makeRequest } = useRequestsContext();
	const {
		options: { marketsOptions, usersOptions, productsOptions },
	} = useCommonDataContext();

	// Data initially fetched from the server
	const [subfiltersInfo, setSubfiltersInfo] = useState();
	const [operationsInfo, setOperationsInfo] = useState();
	const [filterData, setFilterData] = useState();

	// Display data
	const [tableRows, setTableRows] = useState(null);
	const [groupedByTableData, setGroupedByTableData] = useState(null);

	// All filters
	const [filters, dispatchFilters] = useReducer(reducer, defaultFilters);
	const [operations, dispatchOperations] = useReducer(operationsReducer, {});
	const [selectedData, setSelectedData] = useState(defaultSelectedData);

	const setSubfilters = (newSubfilters) => {
		setSelectedData((prev) => ({
			...prev,
			subfilters: newSubfilters,
		}));
	};

	const setDisplayFields = (newDisplayFields) => {
		setSelectedData((prev) => ({
			...prev,
			displayFields: newDisplayFields,
		}));
	};

	const setGroupBys = (newGroupBys) => {
		setSelectedData((prev) => ({
			...prev,
			groupBys: newGroupBys,
		}));
	};

	const setOperationFields = (newOperationFields) => {
		setSelectedData((prev) => ({
			...prev,
			operationFields: newOperationFields,
		}));
	};

	const setSortBy = (newSortBy) => {
		setSelectedData((prev) => ({
			...prev,
			sort: {
				...prev.sort,
				by: newSortBy,
			},
		}));
	};

	const setSortOrder = (newSortOrder) => {
		setSelectedData((prev) => ({
			...prev,
			sort: {
				...prev.sort,
				order: newSortOrder,
			},
		}));
	};

	// Fetch init values for allowed filters, possible subfilters, and possible operations
	const getSubfiltersInfo = async () => {
		const response = await makeRequest(getAllPosibleFiledsToFilter);

		if (!response.data) return;

		const subfiltersInfo = [
			...Object.entries(response.data.orderFields).map(([key, value]) => {
				return { label: 'order.' + key, value };
			}),
			...Object.entries(response.data.contactFields).map(([key, value]) => {
				return { label: 'contact.' + key, value };
			}),
			...Object.entries(response.data.validClaimFields).map(([key, value]) => {
				return { label: 'valid_claim.' + key, value };
			}),
		];

		const operationsInfo = [
			{ label: 'order.price', value: { type: 'Number' } },
			...subfiltersInfo,
		];

		setSubfiltersInfo(() => subfiltersInfo);
		setOperationsInfo(() => operationsInfo);
	};

	const getPosibleFilters = async () => {
		const response = await makeRequest(getFiltersForUser.bind(null));

		if (response.data) {
			const toDisplay = [];
			const filters = [];

			response.data.forEach((data) => {
				data.filters.forEach((filter) => {
					toDisplay.push({ label: filter.name, value: filter });
					filters.push(filter);
				});
			});

			setFilterData(() => filters);
		}
	};

	useEffect(() => {
		getPosibleFilters();
		getSubfiltersInfo();
	}, []);

	return (
		<OrderFilterTableContext.Provider
			value={{
				subfiltersInfo,
				operationsInfo,
				filterData,
				selectedData,
				hasUnfilledRequest,
				tableRows,
				groupedByTableData,
				marketsOptions,
				usersOptions,
				productsOptions,
				setTableRows,
				setGroupBys,
				dispatchOperations,
				setOperationFields,
				setSubfilters,
				setDisplayFields,
				filters,
				dispatchFilters,
				setSortBy,
				setSortOrder,
				operations,
				setSelectedData,
				setGroupedByTableData,
			}}
		>
			{children}
		</OrderFilterTableContext.Provider>
	);
}

export const useOrderFilterTableContext = () => {
	const context = useContext(OrderFilterTableContext);
	if (!context) {
		throw new Error(
			'useOrderFilterTableContext must be used within OrderFilterTableProvider'
		);
	}
	return context;
};
