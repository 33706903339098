import { useState } from 'react';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { useOrderFilterTableContext } from './providers/orderFilterTableProvider';

const useOrderFilterTable = () => {
	const { selectedData, tableRows, groupedByTableData } =
		useOrderFilterTableContext();
	const { hasUnfilledRequest } = useRequestsContext();
	const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

	return {
		isFiltersModalOpen,
		setIsFiltersModalOpen,
		selectedData,
		tableRows,
		groupedByTableData,
		hasUnfilledRequest,
	};
};

export default useOrderFilterTable;
