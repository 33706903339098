export const MAIL_PAYLOAD = {
	order_id: '2023',
	invoice_number: 'FS01092023SK',
	tracking_link: 'https://tandt.posta.sk/en/items/EB667251449SK',
	paymentLink:
		'https://ustav-pro-lingvisticky-vyzkum.cz/anglictina50pluslp2/typ/?utm_source=mail&utm_medium=pay&price_version=v1',
	paymentLink3Days:
		'https://ustav-pro-lingvisticky-vyzkum.cz/anglictina50pluslp2/typ/?utm_source=mail&utm_medium=pay_3days&price_version=v1',
	totalPrice: 1960,
	waybill: '123456789',
	region: 'Praha',
	hasPromotion: true,
	coursename: 'Angielski w 30 dni dla dorosłych',
	paylink: 'https://cj-fasttrack.pl/typ/?utm_campaign=mail&utm_medium=pay',
	name: 'Test Name',
	paymentOrder: [
		{
			course: 'Trójpoziomowy kurs angielskiego',
			voucher: 'PL22222',
		},
		{
			course: 'Trójpoziomowy kurs niemieckiego',
			voucher: 'PL22222',
		},
	],
	orders: [
		{
			index: 1,
			product: 'Tříúrovňový kurz angličtiny',
			price: 1960,
		},
	],
};

export const ATTACHMENT_OPTIONS = [
	{
		label: 'Sales invoice',
		value: 'sales',
	},
	{
		label: 'Correction invoice',
		value: 'correction',
	},
	{
		label: 'None',
		value: '',
	},
];

export const RESTRICTED_ROLES = ['SUPPORT'];

export const DATE_TYPE = Object.freeze({
	FIXED: 'fixed',
	RANGE: 'range'
});
