export const PHONE_PREFIX = {
  cz: "+420 ",
  sk: "+421 ",
  ts: "+44 ",
  ro: "+40 ",
  hr: "+385 ",
  lt: "+370 ",
  hu: "+36 ",
  pl: "+48 ",
  at: "+43 ",
  it: "+39 ",
  de: "+49 ",
  lv: "+371 ",
  bg: "+359 ",
  ee: "+372 ",
  si: "+386 ",
  fr: "+33 ",
};
