import Select from 'react-select';
import {
	BorderLine,
	ColorTitle,
	Form,
	OptionWrapper,
	SaveSelectWrapper,
	SelectsWrapper,
} from './FilterOptonsModal.styled';
import { DIRECTION_OPTIONS } from './constants/options';
import { getSelectCustomStyles } from './helpers/getSelectCustomStyles';
import { FilterOptionFinder } from './components/filterOptionFinder/FilterOptionFinder';
import OperationsUpdater from './components/operationsUpdater/OperationsUpdater';
import ActionButton from '@/common/components/buttons/ActionButton';
import SubmitButton from '@/common/components/buttons/SubmitButton';
import PopUp from '@/common/components/PopUp';
import { AVAILABLE_ORDER_FILEDS } from '@/common/constants/orderFilterFields';
import SelectInput from '@/common/components/SelectInput';
import { Colors } from '@/common/colors/colors';
import Input from '@/common/components/Input';
import useFilterOptionsModal from './useFilterOptionsModal';

const FilterOptions = ({ setIsFiltersModalOpen }) => {
	const {
		subfiltersInfo,
		operationsInfo,
		selectedData,
		marketsOptions,
		dispatchFilters,
		dispatchOperations,
		filters,
		operations,
		usersOptions,
		productsOptions,
		setDisplayFields,
		setSortBy,
		setSortOrder,
		handleSelected,
		handleUpdateGroupBy,
		handleSelectOperationFileds,
		sharedFor,
		setSharedFor,
		handleSaveOrCreateFilter,
		setShowCreate,
		saved,
		showCreate,
		nameRef,
		publicRef,
	} = useFilterOptionsModal();

	return (
		<PopUp setShow={setIsFiltersModalOpen}>
			<div style={{ minHeight: '800px' }}>
				<OptionWrapper>
					<ColorTitle>Fileds:</ColorTitle>
					<BorderLine />
					<Select
						value={selectedData.displayFields}
						onChange={setDisplayFields}
						options={AVAILABLE_ORDER_FILEDS}
						isMulti={true}
						styles={getSelectCustomStyles(800)}
					/>
				</OptionWrapper>
				<OptionWrapper>
					<SelectsWrapper>
						<SelectInput
							width={80}
							name='Sort by'
							color={Colors.darkBlue}
							options={AVAILABLE_ORDER_FILEDS}
							setSelected={setSortBy}
							selected={selectedData.sort?.by}
						/>
						<SelectInput
							width={80}
							selectWidth={150}
							name='Direction'
							color={Colors.darkBlue}
							options={DIRECTION_OPTIONS}
							selected={selectedData.sort?.order || DIRECTION_OPTIONS[0]}
							setSelected={setSortOrder}
						/>
					</SelectsWrapper>
				</OptionWrapper>
				<OptionWrapper>
					<ColorTitle>
						<h3>Filters:</h3>
					</ColorTitle>
					<BorderLine />
				</OptionWrapper>
				<SelectInput
					name='Field'
					color={Colors.darkBlue}
					width={30}
					selected={selectedData.subfilters}
					setSelected={(e) => handleSelected(e)}
					options={subfiltersInfo}
					multiple={true}
					selectWidth={600}
				/>
				<FilterOptionFinder
					data={selectedData.subfilters}
					dispatchFilters={dispatchFilters}
					filters={filters}
					markets={marketsOptions}
					products={productsOptions}
					consultants={usersOptions}
				/>
				<BorderLine />
				<OptionWrapper>
					<ColorTitle>
						<h3>Operations:</h3>
					</ColorTitle>
					<BorderLine />
				</OptionWrapper>
				<SelectInput
					name='Group by'
					selected={selectedData.groupBys}
					color={Colors.darkBlue}
					multiple
					width={90}
					options={operationsInfo?.filter(
						(field) => !Object.keys(operations)?.includes(field.label)
					)}
					selectWidth={600}
					setSelected={handleUpdateGroupBy}
				/>
				<SelectInput
					name='Field'
					color={Colors.darkBlue}
					width={90}
					selected={selectedData.operationFields}
					setSelected={(payload) => handleSelectOperationFileds(payload)}
					options={operationsInfo?.filter(
						(field) => !Object.keys(operations)?.includes(field.label)
					)}
					multiple={true}
					selectWidth={600}
				/>
				<OperationsUpdater
					operations={operations}
					selectedOperationFields={selectedData.operationFields}
					dispatchOperations={dispatchOperations}
				/>
			</div>
			<SaveSelectWrapper>
				<SelectInput
					name='Shared with'
					options={usersOptions}
					selected={sharedFor}
					setSelected={setSharedFor}
					multiple={true}
					selectWidth={550}
					width={110}
					color={Colors.darkBlue}
				/>
				<ActionButton
					onClick={(e) => {
						if (selectedData.filter) {
							handleSaveOrCreateFilter(e);
							return;
						}
						setShowCreate(() => true);
					}}
					defaultText={saved ? saved : 'Create or Save'}
					color={saved ? Colors.green : Colors.orange}
				/>
			</SaveSelectWrapper>
			{showCreate && (
				<PopUp padding='10px 25px' setShow={setShowCreate}>
					<Form onSubmit={(e) => handleSaveOrCreateFilter(e)}>
						<Input width={80} inputRef={nameRef} required name={'Name'} />
						<Input
							width={80}
							inputRef={publicRef}
							checked
							name={'Public'}
							type='checkbox'
						/>

						<SubmitButton style={{ marginTop: '20px' }} text='Create' />
					</Form>
				</PopUp>
			)}
		</PopUp>
	);
};

export default FilterOptions;
