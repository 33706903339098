import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;
