import SelectInput from '@/common/components/SelectInput';
import React from 'react';
import {
	OPERATION_ACTIONS,
	OPERATIONS_OPTIONS,
} from '../../constants/operations';
import { BorderLine } from '../../FilterOptonsModal.styled';
import { Colors } from '@/common/colors/colors';
import { Container, Label } from './OperationsUpdater.styled';

const OperationsUpdater = ({
	selectedOperationFields,
	dispatchOperations,
	operations,
}) => {
	const handleChangeOperation = (operation, field) => {
		dispatchOperations({
			type: OPERATION_ACTIONS.UPDATE,
			payload: {
				operation,
				label: field.label,
				type: field.value.type,
			},
		});
	};

	return (
		<div>
			{selectedOperationFields?.map((field, index) => (
				<Container key={index}>
					<BorderLine />
					<Label>{field.label}:</Label>
					<BorderLine />
					<SelectInput
						showLabel={false}
						options={OPERATIONS_OPTIONS}
						color={Colors.darkBlue}
						width={450}
						setSelected={(e) => handleChangeOperation(e.value, field)}
						selected={OPERATIONS_OPTIONS.find(
							(option) => option.value === operations[field.label]?.operation
						)}
					/>
				</Container>
			))}
		</div>
	);
};

export default OperationsUpdater;
