import { markContactAsPostalCodePromotion, saveConsultantContact } from '@/API/repositories/contact'
import { createSuspicionTicket } from '@/API/repositories/tickets'
import { Colors } from '@/common/colors/colors'
import ActionButton from '@/common/components/buttons/ActionButton'
import Card from '@/common/components/Card'
import Input from '@/common/components/Input'
import SelectInput from '@/common/components/SelectInput'
import { DISABLED_TOOLTIP } from '@/common/constants/suspicion'
import { validatePhoneForCourier } from '@/common/functions/validatePhoneForCourier'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import { useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
	CORRECT_PROVINCE,
	ERROR_PROVINCE,
	NO_RUNNING_PROMO,
	PROVINCE_NOT_FOUND,
	SUCCESS_PROMO
} from '../../constants/texts'
import { ButtonsWrapper, Flex, FormWrapper, Title } from './ContactForm.styled'

const ContactForm = ({ 
	dataToDisplay, 
	country, 
	setCountry,
	reload,
	setPromotionResult,
}) => {
	const { id } = useParams();
	const nameRef = useRef();
  const emailRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const additionalInfoRef = useRef();
  const phoneForCourierRef = useRef();

	const { COUNTRIES } = useCommonDataContext();
	const { addMessage } = useMessageQueueContext();
	const { makeRequest } = useRequestsContext();
	const {
    commonData: { markets },
  } = useCommonDataContext();

	const market = useMemo(() => {
    return markets.find((m) => m.short === dataToDisplay?.country);
  }, [dataToDisplay]);

	const handleSave = async () => {
    const payload = Object();

		const isCourierPhoneInvalid = phoneForCourierRef?.current?.value 
			&& !validatePhoneForCourier(phoneForCourierRef.current.value)

    if (isCourierPhoneInvalid) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    payload.full_name = nameRef.current?.value || dataToDisplay.full_name;
    payload.email = emailRef.current?.value || dataToDisplay.email;
    payload.country = country.value;
    payload.postal_code =
      postalCodeRef.current?.value || dataToDisplay.postal_code;
    payload.city = cityRef.current?.value || dataToDisplay.postal_code;
    payload.street = streetRef.current?.value || dataToDisplay.street;
    payload.additional_info =
      additionalInfoRef.current?.value || dataToDisplay.additional_info;
    payload.phone_number_for_courier = phoneForCourierRef.current.value;

    const response = await makeRequest(
      saveConsultantContact.bind(null, id, payload)
    );

    if (response.data) {
      await reload();
    } else {
      console.error(response.error);
    }
  };

	const handleCreateSuspicionTicket = async () => {
    const response = await makeRequest(
      createSuspicionTicket.bind(null, dataToDisplay._id)
    );
    if (!response?.data) {
      return addMessage("Cannot submit", "error");
    }
    await reload();
    return addMessage("Success", "success");
  };

	const handleCheckPromotion = async (e) => {
    e && e.preventDefault();

    let hasPromotion = {
      region: null,
      message:
       	PROVINCE_NOT_FOUND +
        dataToDisplay.postal_code,
      isGood: false,
    };

    try {
      market.regions.forEach((m) => {
        const postalCode = parseInt(dataToDisplay.postal_code.substring(0, 3));

        const foundCode = m.codes.find(
          (c) => c.from <= postalCode && c.to >= postalCode
        );

        if (foundCode) {
          hasPromotion.region = m;
          hasPromotion.message =
            ERROR_PROVINCE +
            m.label +
            NO_RUNNING_PROMO
        }
      });

      market.promotion.forEach((prom) => {
				const isPromoOk = prom.regions.includes(hasPromotion.region._id) &&
					new Date(prom.from) - new Date(dataToDisplay.created_at) <= 0 &&
					new Date(prom.to) - new Date(dataToDisplay.created_at) >= 0;

        if (isPromoOk) {
          hasPromotion.isGood = true;
          hasPromotion.message =
            CORRECT_PROVINCE +
            hasPromotion.region.label.toUpperCase() +
            SUCCESS_PROMO;
        }
      });
    } catch (e) {
      console.log(e.message);
    }

    await makeRequest(
      markContactAsPostalCodePromotion.bind(
        null,
        dataToDisplay._id,
        hasPromotion.isGood
      )
    );

    await reload();

    setPromotionResult(() => hasPromotion);
  };

	return (
		<Card>
			<FormWrapper>
				<Title>Contact:</Title>
				<Flex>
					<div>
						<Input
							inputRef={nameRef}
							name="Full Name"
							value={dataToDisplay.full_name}
							width={140}
						/>
						<Input
							inputRef={emailRef}
							name="Email"
							value={dataToDisplay.email}
							width={140}
						/>
						<SelectInput
							options={COUNTRIES}
							name="Country"
							selected={country}
							setSelected={setCountry}
							width={140}
						/>
						<Input
							name="Phone for courier"
							value={dataToDisplay.phone_number_for_courier}
							width={140}
							inputRef={phoneForCourierRef}
						/>
						<Input
							name="Phone Number"
							disabled={true}
							value={dataToDisplay.phone_number}
							width={140}
						/>
					</div>
					<div>
						<Input
							inputRef={postalCodeRef}
							name="Postal Code"
							value={dataToDisplay.postal_code}
							width={140}
						/>
						<Input
							inputRef={cityRef}
							name="City"
							value={dataToDisplay.city}
							width={140}
						/>
						<Input
							inputRef={streetRef}
							name="Street"
							value={dataToDisplay.street}
							width={140}
						/>
						<Input
							inputRef={additionalInfoRef}
							name="Info for the courier"
							value={dataToDisplay.additional_info}
							width={140}
						/>
					</div>
				</Flex>
				<ButtonsWrapper>
					{dataToDisplay.country === "cz" && (
						<div>
							<ActionButton
								onClick={(e) => handleCheckPromotion(e)}
								disabled={!dataToDisplay.postal_code}
								defaultText="Check promotion"
								color={Colors.blue}
							/>
						</div>
					)}
					<div>
						<ActionButton
							tooltip={
								dataToDisplay.shouldDisableSuspicion
									? DISABLED_TOOLTIP
									: null
							}
							defaultText="Suspect"
							disabled={dataToDisplay.shouldDisableSuspicion}
							color={Colors.red}
							onClick={() => handleCreateSuspicionTicket()}
						/>
					</div>
					<div>
						<ActionButton defaultText="Save" onClick={handleSave} />
					</div>
				</ButtonsWrapper>
			</FormWrapper>
		</Card>
	)
}

export default ContactForm
