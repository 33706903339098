import { useEffect, useState } from 'react';

const useFilterTable = (rows, columns, howManyToShow) => {
	const [page, setPage] = useState(1);
	const [currentRows, setCurrentRows] = useState([]);
	const [sortInfo, setSortInfo] = useState({});

	const changePage = (e, number) => {
		e.preventDefault();
		setPage((prev) => prev + number);
	};

	const handleSort = (e, index) => {
		e.preventDefault();
		const decision = {};

		const filed = columns[index];

		decision.isAscending = !sortInfo.isAscending;

		if (filed.value.type === 'string') {
			rows = rows.sort((a, b) => {
				const current = a.data[index] ? a.data[index] : '';
				const next = b.data[index] ? b.data[index] : '';

				return sortInfo.isAscending
					? current?.replace(' ', '').localeCompare(next?.replace(' ', ''))
					: next?.replace(' ', '').localeCompare(current?.replace(' ', ''));
			});
		}

		if (filed.value.type === 'number') {
			rows = rows.sort((a, b) => {
				const current = a.data[index] ? a.data[index] : 0;
				const next = b.data[index] ? b.data[index] : 0;

				return sortInfo.isAscending ? current - next : next - current;
			});
		}
		if (filed.value.type === 'date') {
			rows = rows.sort((a, b) => {
				const from = a.data[index] ? new Date(a.data[index])?.getTime() : 0;
				const to = b.data[index] ? new Date(b.data[index])?.getTime() : 0;

				return sortInfo.isAscending ? from - to : to - from;
			});
		}

		decision.index = index;
		setSortInfo(() => decision);
		setCurrentRows(
			rows.slice((page - 1) * howManyToShow, page * howManyToShow)
		);
	};

	const handleSetPage = (e) => {
		e.preventDefault();
		let toSetPage = parseInt(e.target.value);
		if (toSetPage < 1) {
			toSetPage = 1;
		} else if (toSetPage > Math.ceil(rows.length / howManyToShow)) {
			toSetPage = Math.ceil(rows.length / howManyToShow);
		}

		setPage(() => toSetPage);
	};

	useEffect(() => {
		setCurrentRows(
			rows.slice((page - 1) * howManyToShow, page * howManyToShow)
		);
	}, [page]);

	return { handleSort, sortInfo, currentRows, changePage, page, handleSetPage };
};

export default useFilterTable;
