import { AVAILABLE_ORDER_FILEDS } from '@/common/constants/orderFilterFields';

export const defaultFilters = {
	'order.created_at': {
		arithmetic_from: '$gte',
		days: -7,
		type_field: 'Date',
	},
};

export const defaultSelectedData = {
	operationFields: [],
	groupBys: [],
	displayFields: AVAILABLE_ORDER_FILEDS,
	subfilters: [
		{
			label: 'order.created_at',
			value: {
				type: 'Date',
			},
		},
	],
};
