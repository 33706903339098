export const calculateHoursPayment = ({ userId, hours, rate, users }) => {
  const user = users.find((u) => u._id === userId);
  if (!user) {
    return;
  }

  const result = hours * rate;

  const country = user.contract_type.split("_")[0]?.toLowerCase();

  if (country === "ro") {
    return Math.min(result, 4200);
  }

  if (country === "bg") {
    return Math.min(result, 1600);
  }

  return result;
};
