import { useOrderFilterTableContext } from '../../providers/orderFilterTableProvider';
import {
	OPERATION_ACTIONS,
	OPERATIONS,
} from '../../components/orderFilter/constants/operations';
import { getOrderWithFilter } from '@/API/repositories/order';
import { sort } from '../../helpers/sort';
import { generateTableRaws } from '../..//helpers/generateTableRaws';
import { getOrdersToDisplay } from '../../helpers/getOrdersToDisplay';
import { useRequestsContext } from '@/common/hooks/requestHook';

const useSearchBox = (setIsFiltersModalOpen) => {
	const {
		selectedData,
		tableRows,
		filterData,
		setTableRows,
		dispatchFilters,
		dispatchOperations,
		setOperationFields,
		setGroupBys,
		setSelectedData,
		subfiltersInfo,
		filters,
		operations,
		setGroupedByTableData,
	} = useOrderFilterTableContext();
	const { makeRequest } = useRequestsContext();

	const fetchFilteredOrders = async () => {
		const payload = {
			filters,
			operations,
		};

		const response = await makeRequest(getOrderWithFilter.bind(null, payload));

		if (!response.data?.mainTable) return;

		const { mainTable, afterOperationsTable } = response.data;
		const sorted = selectedData.sort?.by
			? sort(
					mainTable,
					selectedData.sort?.by.value.type,
					selectedData.sort?.by.value.label,
					selectedData.sort?.order?.value
			  )
			: mainTable;

		setTableRows(getOrdersToDisplay(sorted, selectedData.displayFields));

		const hasAfterOperations =
			afterOperationsTable && Object.keys(afterOperationsTable).length > 0;

		if (hasAfterOperations) {
			setGroupedByTableData({
				headers: afterOperationsTable.headers,
				orders: generateTableRaws(afterOperationsTable?.flattened),
			});
		}
	};

	const handleSelectFilter = (selected) => {
		setTableRows(null);
		setGroupedByTableData(null);

		const selectedFilter = filterData.find(
			(filtr) => filtr._id === selected.value._id
		);

		dispatchFilters({
			type: 'init',
			data: JSON.parse(selectedFilter.data),
		});

		if (selectedFilter.operations) {
			dispatchOperations({
				type: OPERATION_ACTIONS.INIT,
				payload: JSON.parse(selectedFilter?.operations),
			});
			setOperationFields(
				Object.keys(JSON.parse(selectedFilter?.operations))
					?.filter(
						(key) =>
							JSON.parse(selectedFilter?.operations)[key].operation !==
							OPERATIONS.GROUP_BY
					)
					.map((key) => ({
						label: key,
						value: { type: JSON.parse(selectedFilter?.operations)[key].type },
					}))
			);
			setGroupBys(
				Object.keys(JSON.parse(selectedFilter?.operations))
					?.filter(
						(key) =>
							JSON.parse(selectedFilter?.operations)[key].operation ===
							OPERATIONS.GROUP_BY
					)
					.map((key) => ({
						label: key,
						value: { type: JSON.parse(selectedFilter?.operations)[key].type },
					}))
			);
		} else {
			dispatchOperations({ type: OPERATION_ACTIONS.RESET });
			setOperationFields([]);
		}

		setSelectedData((prev) => ({
			...prev,
			displayFields: selectedFilter.fields
				? JSON.parse(selectedFilter.fields)
				: prev.displayFields,
			filter: selected,
			sort: {
				by: selectedFilter.sort_by
					? JSON.parse(selectedFilter.sort_by)
					: prev.sort?.by,
				order: selectedFilter.sort_type
					? JSON.parse(selectedFilter.sort_type)
					: prev.sort?.order,
			},
			subfilters: Object.entries(JSON.parse(selectedFilter.data)).map(
				([key, value]) => {
					const newValue =
						value.type_field === 'Ref'
							? subfiltersInfo.find((qwe) => qwe.label === key).value
							: { ...value, type: value.type_field };

					return {
						label: key,
						value: newValue,
					};
				}
			),
		}));
	};

	const optionsButtonOnClick = () => {
		setIsFiltersModalOpen(true);
		setTableRows(null);
		setGroupedByTableData(null);
	};

	const searchButtonOnClick = () => {
		setTableRows(null);
		setGroupedByTableData(null);
		fetchFilteredOrders();
	};

	return {
		selectedData,
		tableRows,
		handleSelectFilter,
		filterData,
		optionsButtonOnClick,
		searchButtonOnClick,
	};
};

export default useSearchBox;
