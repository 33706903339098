import { useEffect, useState } from 'react';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { getMailTemplates } from '@/API/repositories/mailTemplate';
import { getMailTests } from '@/API/repositories/mailTest';
import { getMailCategories } from '@/API/repositories/mailCategory';
import userManager from '@/API/userManager';
import { RESTRICTED_ROLES } from './utils/constants';

const useEmailCategory = () => {
	const [mailTemplateOptions, setMailTemplateOptions] = useState([]);
	const [data, setData] = useState([]);
	const { hasUnfilledRequest, makeRequest } = useRequestsContext();
	const [element, setElement] = useState();
	const [filterRegex, setFilterRegex] = useState();

	const isRestricted = RESTRICTED_ROLES.includes(userManager.getUser().role);

	const handleLoadData = async () => {
		const mailTemplateResponse = await makeRequest(getMailTemplates);

		if (mailTemplateResponse.data) {
			setMailTemplateOptions(() =>
				mailTemplateResponse.data.map((template) => ({
					label: template.template_sengrid_name,
					value: template._id,
				}))
			);
		}

		await makeRequest(getMailTests);

		const response = await makeRequest(getMailCategories);

		if (response.data) {
			setData(() => response.data);
		}
	};

	useEffect(() => {
		handleLoadData();
	}, []);

	const handleChangeRegex = (e) => {
		if (e.target.value.length >= 3) {
			return setFilterRegex(() => e.target.value);
		}

		return setFilterRegex(() => null);
	};

	return {
		hasUnfilledRequest,
		isRestricted,
		setElement,
		handleChangeRegex,
		element,
		handleLoadData,
		mailTemplateOptions,
		data,
		filterRegex,
	};
};

export default useEmailCategory;
