import React from 'react';
import { ButtonsWrapper, SearchBarWrapper } from './SearchBoxStyled';
import SelectInput from '@/common/components/SelectInput';
import ActionButton from '@/common/components/buttons/ActionButton';
import CSV from '@/common/components/CSV';
import { Colors } from '@/common/colors/colors';
import useSearchBox from './useSearchBox';

const SearchBox = ({ getCustomStyles, setIsFiltersModalOpen }) => {
	const {
		selectedData,
		tableRows,
		handleSelectFilter,
		filterData,
		optionsButtonOnClick,
		searchButtonOnClick,
	} = useSearchBox(setIsFiltersModalOpen);

	return (
		<SearchBarWrapper>
			<SelectInput
				value={selectedData.filter}
				setSelected={(item) => handleSelectFilter(item)}
				options={filterData?.map((filter) => ({
					label: filter.name,
					value: filter,
				}))}
				styles={getCustomStyles(300)}
				name={'Filters'}
				color={Colors.darkBlue}
				selectWidth={250}
				width={60}
			/>
			<ButtonsWrapper>
				<ActionButton defaultText={'Options'} onClick={optionsButtonOnClick} />
				<ActionButton defaultText={'Search'} onClick={searchButtonOnClick} />
				{tableRows && (
					<CSV
						filename={'orders.csv'}
						header={selectedData.displayFields.map((item) => item['label'])}
						data={tableRows.map((data) => data.data)}
					/>
				)}
			</ButtonsWrapper>
		</SearchBarWrapper>
	);
};

export default SearchBox;
