import Input from '@/common/components/Input';
import SelectInput from '@/common/components/SelectInput';
import {
	BorderLine,
	ColorTitle,
	OptionWrapper,
} from '../../../FilterOptonsModal.styled';

export const FilterOptionFinderString = ({
	fieldData,
	filters,
	dispatchFilters,
}) => {
	const handleOnChange = (value) => {
		if (value.length > 0) {
			return dispatchFilters({
				type: 'add',
				key: fieldData.label,
				value: value,
				type_field: 'String',
			});
		}
		return dispatchFilters({
			type: 'remove',
			key: fieldData.label,
			value: value,
		});
	};

	const handleOnChangeEnum = (value) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			value: value.map((v) => v.value),
			type_field: 'String',
			enumValues: fieldData.value.enumValues,
		});
	};

	if (fieldData.value.enumValues) {
		const options = fieldData.value.enumValues.map((option) => {
			return { label: option, value: option };
		});

		const current = filters[fieldData.label]?.value;

		return (
			<OptionWrapper>
				<ColorTitle>{fieldData.label}:</ColorTitle>
				<BorderLine />
				<SelectInput
					showLabel={false}
					options={options}
					selected={options.filter((option) => current?.includes(option.value))}
					setSelected={(e) => handleOnChangeEnum(e)}
					multiple={true}
					selectWidth={500}
				/>
			</OptionWrapper>
		);
	}

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<Input
				showLabel={false}
				value={filters[fieldData.label]?.value}
				onChange={(e) => handleOnChange(e.target.value)}
			/>
		</OptionWrapper>
	);
};
