import { calcConsultantHours } from "./calcConsultantHours";
import { round } from "../../../helpers/round";

export const calcHoursPayment = (consultantResults) => {
  const result = round(
    consultantResults?.data[0]._consultant_hours.reduce(
      (prev, next) => prev + calcConsultantHours(next),
      0
    ) * consultantResults?._month_working_info.hourly_rate
  );

  const country = consultantResults?._month_working_info?.contract_type
    ?.split("_")[0]
    ?.toLowerCase();

  const hours = consultantResults?._month_working_info?.contract_hours;

  if (country === "ro") {
    return Math.min(result, 4200);
  }

  if (country === "bg") {
    if (hours === 6) {
      return Math.min(result, 1200);
    }

    return Math.min(result, 1600);
  }

  return result;
};
