import React from 'react';
import { getMailTemplates } from '../../../API/repositories/mailTemplate';
import { ButtonCreateWrapper, Wrapper } from '../../../common/styles/Mails';
import Loading from '../../../common/components/Loading';
import Input from '../../../common/components/Input';
import { Colors } from '../../../common/colors/colors';
import {
	createMailCategory,
	getMailCategories,
	updateMailCategory,
} from '../../../API/repositories/mailCategory';
import { getMarkets } from '../../../API/repositories/market';
import { getProducts } from '../../../API/repositories/product';
import { getOrdersToMailByQuery } from '../../../API/repositories/mail';
import ActionButton from '@/common/components/buttons/ActionButton';
import MailTemplateElement from './components/mailTemplateElement/MailTemplateElement';
import useEmailCategory from './useEmailCategory';

const MailCategory = () => {
	const {
		hasUnfilledRequest,
		isRestricted,
		setElement,
		handleChangeRegex,
		element,
		handleLoadData,
		mailTemplateOptions,
		data,
		filterRegex,
	} = useEmailCategory();

	const getIconByBool = (bool) => {
		if (!!bool) {
			return (
				<i
					className='fa fa-check'
					style={{ color: 'green', fontSize: '18px' }}
				/>
			);
		}

		return (
			<i className='fa fa-remove' style={{ color: 'red', fontSize: '18px' }} />
		);
	};

	return (
		<>
			{hasUnfilledRequest(
				createMailCategory,
				updateMailCategory,
				getMailCategories,
				getProducts,
				getMarkets,
				getOrdersToMailByQuery,
				getMailTemplates
			) && <Loading />}
			<Wrapper>
				<ButtonCreateWrapper>
					{!isRestricted && (
						<ActionButton onClick={() => setElement({})} defaultText='Create' />
					)}
					<Input
						width={100}
						inputWidth={200}
						onChange={(e) => handleChangeRegex(e)}
						name='Includer'
						color={Colors.darkBlue}
					/>
				</ButtonCreateWrapper>
				{element && (
					<MailTemplateElement
						element={element}
						setElement={setElement}
						reload={handleLoadData}
						templates={mailTemplateOptions}
					/>
				)}
				<table className='styled-table'>
					<thead>
						<tr>
							<th colSpan={6}>Mail Categories</th>
						</tr>
						<tr>
							<th>No.</th>
							<th>Name</th>
							<th>Cron</th>
							<th>Active</th>
							<th>Send once</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody className='queue'>
						{data[0] && mailTemplateOptions[0] ? (
							data
								.filter((e) =>
									filterRegex ? e.name.includes(filterRegex) : true
								)
								.map((element, i) => (
									<tr key={element._id}>
										<td>{i + 1}.</td>
										<td>{element.name}</td>
										<td>{element.cron_time}</td>
										<td>{getIconByBool(element.active)}</td>
										<td>{getIconByBool(element.should_send_mail_once)}</td>
										<td>
											{!isRestricted && (
												<i
													className='fa fa-edit animation-scale'
													style={{ cursor: 'pointer' }}
													onClick={() => setElement(element)}
												/>
											)}
										</td>
									</tr>
								))
						) : (
							<tr>
								<td colSpan={6}>There is no data</td>
							</tr>
						)}
					</tbody>
				</table>
			</Wrapper>
		</>
	);
};

export default MailCategory;
