import {
	ButtonSaveWrapper,
	Title,
	SubTitle,
	TestingWrapper,
	WrapperCategory,
	LineWrapper,
} from '@/common/styles/Mails';
import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/Input';
import { Colors } from '@/common/colors/colors';
import SelectInput from '@/common/components/SelectInput';
import TextArea from '@/common/components/TextArea';
import ActionButton from '@/common/components/buttons/ActionButton';
import NewConditionalField from '../newConditionalField/NewConditionalField';
import { PropertiesWrapper, Property } from '../../MailCategory.styled';
import GroupConfig from '../groupConfig/GroupConfig';
import GroupConfigTable from '../groupConfigTable/GroupConfigTable';
import SubmitButton from '@/common/components/buttons/SubmitButton';
import CategoryFilter from '../categoryFilter/CategoryFilter';
import { ATTACHMENT_OPTIONS, MAIL_PAYLOAD } from '../../utils/constants';
import useMailTemplateElement from './useMailTemplateElement';

const MailTemplateElement = ({ element, setElement, reload, templates }) => {
	const hook = useMailTemplateElement(element, setElement, reload);

	return (
		<>
			<PopUp setShow={setElement}>
				<WrapperCategory>
					<Title>
						Mail Category{' '}
						<i
							className='fa fa-circle-info animation-scale'
							style={{ cursor: 'pointer' }}
							onClick={() => hook.setShowInfo(() => true)}
						/>
					</Title>
					<form onSubmit={(e) => hook.handleSave(e)}>
						<SubTitle>Settings: </SubTitle>
						<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
							<div>
								<Input
									inputRef={hook.categoryNameRef}
									width={150}
									inputWidth={350}
									name='Name'
									value={element.name}
									color={Colors.darkBlue}
									requiredSign
									required
								/>
								<Input
									inputRef={hook.typRef}
									width={150}
									inputWidth={350}
									name='TYP url part'
									value={element.typ_url_part}
									color={Colors.darkBlue}
								/>
								<Input
									inputRef={hook.activeRef}
									type='checkbox'
									width={150}
									inputWidth={350}
									disabled={!element._id}
									name='Active'
									checked={element.active}
									color={Colors.darkBlue}
								/>
								<ActionButton
									text='add conditional'
									onClick={() => hook.setIsNewConditionaFieldOpen(true)}
								/>
								<div
									style={{
										margin: '10px 0',
										display: 'flex',
										flexDirection: 'column',
										gap: '14px',
									}}
								>
									{hook.conditionalFields?.map((field) => (
										<div key={field.value}>
											{field.value} by {field.fieldBy}
											<PropertiesWrapper>
												{field.properties.map((prop) => (
													<Property
														onClick={() =>
															hook.handleRemoveConditioalField(prop.timestamp)
														}
														key={prop.timestamp}
													>
														{prop.property} - {prop.value}
													</Property>
												))}
											</PropertiesWrapper>
										</div>
									))}
								</div>
								<ActionButton
									text='add group'
									onClick={() => hook.setShowGroupConfig(true)}
								/>
								{!!hook.groupConfigs?.length && (
									<GroupConfigTable
										templates={templates}
										groupConfigs={hook.groupConfigs}
										setShowGroupConfig={hook.setShowGroupConfig}
									/>
								)}
							</div>
							<div>
								<Input
									link={'https://crontab.guru/'}
									value={element.cron_time}
									inputRef={hook.cronTimeRef}
									width={150}
									inputWidth={150}
									name='Cron time'
									color={Colors.darkBlue}
									requiredSign
									required
								/>
								<SelectInput
									name={'Product'}
									width={150}
									selectWidth={350}
									color={Colors.darkBlue}
									options={hook.products}
									selected={hook.selectedProduct}
									setSelected={hook.setSelectedProduct}
									required
								/>
								<SelectInput
									name='Attachment'
									width={150}
									selectWidth={350}
									color={Colors.darkBlue}
									options={ATTACHMENT_OPTIONS}
									selected={hook.selectedAttachment}
									setSelected={hook.setSelectedAttachment}
								/>
								<Input
									inputRef={hook.shouldSendOnceRef}
									type='checkbox'
									width={150}
									inputWidth={350}
									name='Send mail once'
									checked={
										element.should_send_mail_once === undefined
											? true
											: element.should_send_mail_once
									}
									color={Colors.darkBlue}
								/>
								<Input
									inputRef={hook.shouldSendOnWeekendRef}
									type='checkbox'
									width={150}
									inputWidth={350}
									name='On weekend'
									checked={element.should_send_on_weekend}
									color={Colors.darkBlue}
								/>
								<Input
									inputRef={hook.shouldSendCmsPaymentUrlRef}
									type='checkbox'
									width={150}
									inputWidth={350}
									name='Payment url'
									checked={element.should_include_cms_payment_link}
									color={Colors.darkBlue}
								/>
							</div>
						</div>
						{hook.consultants[0] &&
							hook.markets[0] &&
							hook.products[0] &&
							hook.queues[0] && (
								<CategoryFilter
									filters={hook.filters}
									dispatchFilters={hook.dispatchFilters}
									selectedData={hook.selected}
									setSelectedData={hook.setSelected}
									markets={hook.markets}
									products={hook.products}
									queues={hook.queues}
									consultants={hook.consultants}
								/>
							)}
						<div
							style={{
								display: 'flex',
								justifyContent: 'right',
							}}
						>
							<ActionButton
								onClick={() => hook.handleFindOrdersByQuery()}
								disabled={!element._id}
								defaultText='Check query result'
							/>
						</div>
						<SubTitle>Testing: </SubTitle>
						<TestingWrapper>
							<TextArea
								textAreaRef={hook.textAreaRef}
								label='Sending object'
								placeholder='object'
								defaultValue={JSON.stringify(MAIL_PAYLOAD, null, '\t')}
								fontSize={'12px'}
								width='500px'
								minHeight='210px'
							/>
							<div>
								<Input
									showLabel={false}
									inputRef={hook.emailTestRef}
									inputWidth={350}
									name='Mail'
									type='email'
									placeholder='Type email...'
									color={Colors.darkBlue}
								/>
								{!!hook.selectedAttachment?.value?.length && (
									<Input
										type='file'
										inputRef={hook.fileRef}
										showLabel={false}
										accept='application/pdf'
										inputWidth={350}
									/>
								)}
								<ActionButton
									disabled={!element._id}
									onClick={() => hook.handleTest()}
									defaultText='Test'
								/>
							</div>
						</TestingWrapper>
						<ButtonSaveWrapper>
							<SubmitButton text='Save' />
						</ButtonSaveWrapper>
					</form>
				</WrapperCategory>
				{hook.isNewConditionaFieldOpen && (
					<NewConditionalField
						setConditionalFields={hook.setConditionalFields}
						setShow={hook.setIsNewConditionaFieldOpen}
					/>
				)}
			</PopUp>
			{hook.queryResult && (
				<PopUp setShow={hook.setQueryResult}>
					<table className='styled-table'>
						<thead>
							<tr>
								<th>No.</th>
								<th>Full name</th>
								<th>Email</th>
								<th>Market</th>
								<th>Product</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody className='queue'>
							{hook.queryResult[0] ? (
								hook.queryResult.map((result, i) => (
									<tr key={result._id}>
										<td>{i + 1}.</td>
										<td>{result.contact.full_name}</td>
										<td>{result.contact.email}</td>
										<td>{result.market.name}</td>
										<td>{result.product.name}</td>
										<td>
											<i
												className='fa fa-address-book animation-scale'
												style={{ color: Colors.darkBlue, cursor: 'pointer' }}
												onClick={() => {
													window.open(
														`/dashboard/order/${result._id}`,
														'_blank'
													);
												}}
											/>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={6}>There is no data</td>
								</tr>
							)}
						</tbody>
					</table>
				</PopUp>
			)}
			{hook.showGroupConfig && (
				<GroupConfig
					setGroupConfigs={hook.setGroupConfigs}
					groupConfigs={hook.groupConfigs}
					templatesOptions={templates}
					setShow={hook.setShowGroupConfig}
					config={hook.showGroupConfig}
				/>
			)}
			{hook.showInfo && (
				<PopUp setShow={hook.setShowInfo}>
					<Title>Wskazówki do CronTime:</Title>
					<LineWrapper>1. Musi byc 6 spacji nie mniej nie wiecej.</LineWrapper>
					<LineWrapper>
						2. Default to * * * * * * - wtedy beda sie wykonywac co sekunde
					</LineWrapper>
					<LineWrapper>
						3. 1(wsza) giwazdka to sekundy (1-60) , 2(ga) gwiazdka to
						minuty(1-60), 3(cia) to godziny (1-24), 4 to dni w miesiacu , 5 to
						miesiace, 6 to dni w tygodniu (1-7 gdzie 1 to poniedzialek)
					</LineWrapper>
					<LineWrapper>Przyklady:</LineWrapper>
					<LineWrapper>45 * * * * * - co minute w 45 sekundzie</LineWrapper>
					<LineWrapper>0 10 * * * * - co godzine w 10 minucie</LineWrapper>
					<LineWrapper>
						0 */30 9-17 * * * - co 30 minut w godzinach 9 - 17{' '}
					</LineWrapper>
					<LineWrapper>
						0 30 11 * * 1-5 - od poniedzialku do piatku o 11:30
					</LineWrapper>
					W razie czego to tu mozna sie czego dowiedziec{' '}
					<a
						href='https://docs.nestjs.com/techniques/task-scheduling'
						target='_blank'
						rel='noreferrer'
					>
						https://docs.nestjs.com/techniques/task-scheduling
					</a>
					. Klikajac na cron time mozna wejsc w testera.
				</PopUp>
			)}
		</>
	);
};

export default MailTemplateElement;
