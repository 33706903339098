import moment from "moment";
import React, { useLayoutEffect, useState } from "react";
import {
  getConsultantEffectiveSalary,
  getEffectiveSummarizeConsultant,
} from "../../../API/repositories/reports";
import userManager from "../../../API/userManager";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import EffectivePaymentTable from "../salary/EffectivePaymentTable";
import ManagerEffectivePaymentTable from "@/pages/consultant/salary/managerEffectivePaymentTable/ManagerEffectivePaymentTable";
import ManagerSummarize from "@/pages/consultant/salary/managerSummarize/ManagerSummarize";
import { CONSULTANT_MANAGER_ROLES } from "@/common/constants/consultantRoles";
import EffectivePaymentSummarize from "@/pages/consultant/salary/effectivePaymentSummarize/EffectivePaymentSummarize";
import { findUserMarket } from "@/common/functions/findUserMarket";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import {
  InfoWrapper,
  SpecialInfoWrapper,
  Wrapper,
} from "./ConsultantSalary.styled";
import { months } from "./constants/months";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ConsultantSalary = () => {
  const [summarizeData, setSummarizeData] = useState();
  const [currentMonthEffectiveSalary, setCurrentMonthEffectiveSalary] =
    useState(null);
  const [lastMonthEffectiveSalary, setLastMonthEffectiveSalary] =
    useState(null);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const {
    commonData: { markets, queues, users },
  } = useCommonDataContext();
  const { translation } = useTranslationContext();

  const handleGetConsultantEffectiveSalary = async () => {
    const currnetMonthFrom = moment().startOf("month").toISOString();
    const currnetMonthTo = moment().endOf("month").toISOString();

    let response = await makeRequest(
      getConsultantEffectiveSalary.bind(
        null,
        userManager.getUser().id,
        currnetMonthFrom,
        currnetMonthTo
      )
    );

    if (response?.data) {
      setCurrentMonthEffectiveSalary(() => response.data);
    }

    const lastMonthFrom = moment()
      .subtract("1", "month")
      .startOf("month")
      .toISOString();
    const lastMonthTo = moment()
      .subtract("1", "month")
      .endOf("month")
      .toISOString();

    response = await makeRequest(
      getConsultantEffectiveSalary.bind(
        null,
        userManager.getUser().id,
        lastMonthFrom,
        lastMonthTo
      )
    );

    if (response?.data) {
      setLastMonthEffectiveSalary(() => response.data);
    }

    const userMarket = findUserMarket(
      markets,
      queues,
      userManager.getUser().id
    );

    const marketUsers = users
      .filter((user) => {
        const market = findUserMarket(markets, queues, user._id);

        return market?._id === userMarket?._id;
      })
      .map((u) => u._id);

    const effectiveSummarizeConsultantResponse = await makeRequest(
      getEffectiveSummarizeConsultant.bind(
        null,
        userManager.getUser().id,
        marketUsers,
        months
      )
    );

    if (effectiveSummarizeConsultantResponse?.data) {
      setSummarizeData(() => effectiveSummarizeConsultantResponse.data);
    }
  };

  useLayoutEffect(() => {
    if (markets.length && queues.length && users.length) {
      handleGetConsultantEffectiveSalary();
    }
  }, [markets, queues, users]);

  return (
    <Wrapper>
      {hasUnfilledRequest(
        getConsultantEffectiveSalary,
        getEffectiveSummarizeConsultant
      ) && <Loading />}
      <SpecialInfoWrapper>
        <InfoWrapper>{translation["special_klaudia_info"]}</InfoWrapper>
      </SpecialInfoWrapper>
      {!!currentMonthEffectiveSalary?.data?.length && (
        <EffectivePaymentTable
          data={currentMonthEffectiveSalary}
          from={translation["currnetMonthFrom"]}
          to={translation["currnetMonthTo"]}
        />
      )}
      {!!currentMonthEffectiveSalary?.team?.length && (
        <ManagerEffectivePaymentTable
          team={currentMonthEffectiveSalary.team}
          from={translation["currnetMonthFrom"]}
          to={translation["currnetMonthTo"]}
        />
      )}
      {lastMonthEffectiveSalary?.data?.length && (
        <EffectivePaymentTable
          data={lastMonthEffectiveSalary}
          from={translation["lastMonthFrom"]}
          to={translation["lastMonthTo"]}
        />
      )}
      {!!lastMonthEffectiveSalary?.team?.length && (
        <ManagerEffectivePaymentTable
          team={lastMonthEffectiveSalary.team}
          from={translation["lastMonthFrom"]}
          to={translation["lastMonthTo"]}
        />
      )}
      {!lastMonthEffectiveSalary?.data?.length &&
        !currentMonthEffectiveSalary?.data?.length &&
        !lastMonthEffectiveSalary?.team?.length &&
        !currentMonthEffectiveSalary?.team?.length && (
          <h4>{translation["noData"]}</h4>
        )}
      {summarizeData && (
        <EffectivePaymentSummarize summarizeData={summarizeData} />
      )}
      {CONSULTANT_MANAGER_ROLES.includes(userManager.getUser().role) &&
        summarizeData && <ManagerSummarize summarizeData={summarizeData} />}
    </Wrapper>
  );
};

export default ConsultantSalary;
