import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import ListTable from "../../../common/components/ListTable";
import Loading from "../../../common/components/Loading";
import PopUp from "../../../common/components/PopUp";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import ProductNew from "./ProductNew";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { ShowArrow } from "../user/UserList.styled";
import { Colors } from "@/common/colors/colors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

const TableTitle = styled.h3`
  width: 100%;
  color: ${Colors.darkBlue};
  margin: 20px 4px 10px;
`;

const QueueTableWrapper = styled.div`
  opacity: ${(props) => (props.show ? 1 : 0.1)};
  overflow: hidden;
  height: ${(props) => (props.show ? "fit-content" : "100px")};
  transition: all 0.3s;

`;

const ProductList = () => {
  const [products, setProducts] = useState({
    active: [],
    unactive: []
  });
  const column = ["no", "name", "short"];

  const [showNew, setShowNew] = useState(false);
  const { commonData } = useCommonDataContext();
  const { products: productsData } = commonData;
  const [isActiveDisplayed, setIsActiveDisplayed] = useState(true)
  const [isUnactiveDisplayed, setIsUnactiveDisplayed] = useState(false)

  const { hasUnfilledRequest } = useRequestsContext();

  const handleGetProducts = async () => {
    const active = []
    const unactive = []

    productsData.forEach((element) => {
      const rowToDisplay = []

      rowToDisplay.push(element.name);
      rowToDisplay.push(element.short);

      const productListItem = {
        data: rowToDisplay,
        _id: element._id,
      }

      if(element.is_active === false)
        unactive.push(productListItem)
      else
        active.push(productListItem)
    })

    setProducts({
      active,
      unactive
    })
  };

  useLayoutEffect(() => {
    if(showNew === false)
      handleGetProducts();
  }, [showNew]);

  return (
    <>
      {hasUnfilledRequest(handleGetProducts) && <Loading />}
      <Wrapper>
        <ActionButton
          style={{ width: "fit-content" }}
          onClick={() => setShowNew(true)}
          defaultText="Create product"
        />

        <TableTitle onClick={() => setIsActiveDisplayed((prev) => !prev)}>
          Active Products{" "}
          <ShowArrow show={isActiveDisplayed} className="fa fa-arrow-down" />
        </TableTitle>
        <QueueTableWrapper show={isActiveDisplayed}>
          <ListTable column={column} row={products.active} entityName={"product"} />
        </QueueTableWrapper>
        
        <TableTitle onClick={() => setIsUnactiveDisplayed((prev) => !prev)}>
          Unactive Products{" "}
          <ShowArrow show={isUnactiveDisplayed} className="fa fa-arrow-down" />
        </TableTitle>
        <QueueTableWrapper show={isUnactiveDisplayed}>
          <ListTable column={column} row={products.unactive} entityName={"product"} />
        </QueueTableWrapper>

      </Wrapper>     
      {showNew && (
        <PopUp setShow={setShowNew}>
          <ProductNew close={() => setShowNew(false)} />
        </PopUp>
      )}
    </>
  );
};

export default ProductList;
