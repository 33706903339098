import { useEffect, useReducer, useRef, useState } from 'react';
import { useRequestsContext } from '@/common/hooks/requestHook';
import {
	createMailCategory,
	testCategoryEmail,
	updateMailCategory,
} from '@/API/repositories/mailCategory';
import { getUsers } from '@/API/repositories/user';
import { getMarkets } from '@/API/repositories/market';
import { getProducts } from '@/API/repositories/product';
import { getOrdersToMailByQuery } from '@/API/repositories/mail';
import { getQueues } from '@/API/repositories/queue';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import { reducer } from '../../utils/reducer';
import { ATTACHMENT_OPTIONS, MAIL_PAYLOAD } from '../../utils/constants';

const useMailTemplateElement = (element, setElement, reload) => {
	const [filters, dispatchFilters] = useReducer(reducer, {});
	const [showGroupConfig, setShowGroupConfig] = useState(false);
	const [groupConfigs, setGroupConfigs] = useState(element.group_configs || []);
	const [selected, setSelected] = useState([]);
	const [queryResult, setQueryResult] = useState();
	const [isNewConditionaFieldOpen, setIsNewConditionaFieldOpen] = useState();
	const [conditionalFields, setConditionalFields] = useState(
		element.conditional_fields ? JSON.parse(element.conditional_fields) : null
	);

	const { makeRequest } = useRequestsContext();
	const { addMessage } = useMessageQueueContext();
	const [showInfo, setShowInfo] = useState(false);
	const [markets, setMarkets] = useState([]);
	const [products, setProducts] = useState([]);
	const [queues, setQueues] = useState([]);

	const [consultants, setConsultants] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState();
	const [selectedAttachment, setSelectedAttachment] = useState(
		ATTACHMENT_OPTIONS.find(
			(option) => option.value === element.attachment
		) || { label: 'None', value: '' }
	);
	const categoryNameRef = useRef();
	const cronTimeRef = useRef();
	const activeRef = useRef();
	const shouldSendOnceRef = useRef();
	const shouldSendOnWeekendRef = useRef();
	const shouldSendCmsPaymentUrlRef = useRef();
	const emailTestRef = useRef();
	const textAreaRef = useRef();
	const fileRef = useRef();
	const typRef = useRef();

	const handleFindOrdersByQuery = async () => {
		filters.should_send_mail_once = element.should_send_mail_once;
		filters.mailCategoryId = element._id;

		const response = await makeRequest(
			getOrdersToMailByQuery.bind(null, filters)
		);

		if (response.data) {
			setQueryResult(() => response.data);
		}
	};

	const handleSave = async (e) => {
		e && e.preventDefault();

		if (cronTimeRef?.current?.value?.split(' ').length != 6) {
			addMessage('CRON TIME SHOULD HAVE 6 spaces check info', 'error');
			return;
		}

		if (cronTimeRef?.current?.value) {
			const hasError = cronTimeRef?.current?.value
				?.split(' ')
				.filter((element) => element.includes('/*'));

			if (hasError.length > 0) {
				addMessage('Not valid Cron, check info');
				return;
			}
		}

		if (selectedProduct.length === 0 || !groupConfigs?.length) {
			addMessage('Please add product and  configs', 'error');
			return;
		}

		const payload = {};

		payload._id = element._id || null;
		payload.name = categoryNameRef.current?.value || element.name;
		payload.group_configs = groupConfigs;
		payload.product = selectedProduct.value;
		payload.cron_time = cronTimeRef.current.value || element.cron_time;
		payload.active = activeRef.current?.checked || false;
		payload.filters = JSON.stringify(filters);
		payload.should_send_mail_once = shouldSendOnceRef.current?.checked || false;
		payload.should_send_on_weekend =
			shouldSendOnWeekendRef.current?.checked || false;
		payload.attachment = selectedAttachment.value;
		payload.conditional_fields = conditionalFields?.length
			? JSON.stringify(conditionalFields)
			: null;
		payload.typ_url_part = typRef.current?.value;
		payload.should_include_cms_payment_link =
			shouldSendCmsPaymentUrlRef.current?.checked || false;

		let response;

		if (payload._id) {
			response = await makeRequest(updateMailCategory.bind(null, payload));
		} else {
			response = await makeRequest(createMailCategory.bind(null, payload));
		}

		if (response.data) {
			addMessage('Saved', 'success');
			await reload();
			setElement(() => null);
		}
	};

	const handleLoadData = async () => {
		const responseProducts = await makeRequest(getProducts);
		if (responseProducts.data) {
			const productOptions = responseProducts.data.map((data) => {
				return { label: data.name, value: data._id };
			});

			setProducts(() => productOptions);

			if (element.product) {
				setSelectedProduct(() =>
					productOptions.find((p) => element.product === p.value)
				);
			}
		}

		const responseQueues = await makeRequest(getQueues);

		if (responseQueues.data) {
			const queuesOptions = responseQueues.data.map((data) => {
				return { label: data.name, value: data._id };
			});

			setQueues(() => [...queuesOptions, { label: 'Null', value: null }]);
		}

		const responseMarket = await makeRequest(getMarkets);
		if (responseMarket.data) {
			setMarkets(() =>
				responseMarket.data.map((data) => {
					return { label: data.name, value: data._id };
				})
			);
		}

		const responseConsultant = await makeRequest(getUsers);
		if (responseConsultant.data) {
			setConsultants(() =>
				responseConsultant.data.map((data) => {
					return { label: data.name + ' ' + data.surname, value: data._id };
				})
			);
		}

		dispatchFilters({
			type: 'init',
			data: JSON.parse(element.filters),
		});

		setSelected(() =>
			Object.entries(JSON.parse(element.filters)).map(([key, value]) => {
				return {
					label: key,
					value: { ...value, type: value.type_field },
				};
			})
		);
	};

	const handleRemoveConditioalField = (timestamp) => {
		setConditionalFields((prev) => {
			prev.forEach((field) => {
				field.properties = field.properties?.filter(
					(prop) => prop.timestamp !== timestamp
				);
			});
			return prev.filter((el) => el.properties?.length);
		});
	};

	const handleTest = async () => {
		if (!!selectedAttachment?.value?.length && !fileRef.current?.files[0]) {
			return addMessage('Select file', 'error');
		}

		const formData = new FormData();

		formData.append('file', fileRef.current?.files[0]);
		formData.append('category_id', element._id);
		formData.append('email', emailTestRef.current.value);
		formData.set('mail_payload', textAreaRef.current?.value || MAIL_PAYLOAD);

		const response = await makeRequest(testCategoryEmail.bind(null, formData));

		if (response.data) {
			addMessage('Sent', 'success');
		} else {
			addMessage('Error', 'error');
		}
	};

	useEffect(() => {
		handleLoadData();
	}, []);

	return {
		setShowInfo,
		handleSave,
		categoryNameRef,
		typRef,
		activeRef,
		setIsNewConditionaFieldOpen,
		conditionalFields,
		handleRemoveConditioalField,
		setShowGroupConfig,
		groupConfigs,
		cronTimeRef,
		products,
		selectedProduct,
		setSelectedProduct,
		selectedAttachment,
		setSelectedAttachment,
		shouldSendOnceRef,
		shouldSendOnWeekendRef,
		shouldSendCmsPaymentUrlRef,
		consultants,
		markets,
		queues,
		filters,
		dispatchFilters,
		selected,
		setSelected,
		handleFindOrdersByQuery,
		textAreaRef,
		emailTestRef,
		fileRef,
		handleTest,
		isNewConditionaFieldOpen,
		setConditionalFields,
		queryResult,
		setQueryResult,
		showGroupConfig,
		setGroupConfigs,
		showInfo,
	};
};

export default useMailTemplateElement;
