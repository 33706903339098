import moment from 'moment';

export const TO_TRANSLATE = {
	placeholder: 'Type email, name, phone number',
	text1: 'Language',
	text2: 'Password',
	create_by_vat: 'Create by company vat id',
	create_by_different_id: 'Create by different company id',
	vat_id: 'Vat id',
	company_country: 'Company country',
	company_id: 'Company id',
	add_comment: 'Add comment...',
	maps: 'go to maps',
	create_invoice: 'Create invoice',
	check_company: 'Check company',
	vat_id_check: 'Go to vat id check',
	different_id_check: 'Go to different company id check',
	address: 'Address',
	company_name: 'Company name',
	old_way: 'Old way',
	company_street_and_number: 'Company street and number',
	company_postal_code: 'Company postal code',
	company_city: 'Company city',
	find_in_google: 'google search',
	sendSupportMail: 'Send Support Mail',
	mailError: 'Error',
	mailSuccess: 'Success',
	reamainingTime: 'Remaining time',
	minutes: 'minutes',
	seconds: 'seconds',
	fullName: 'Full name',
	dequeuedCount: 'Dequeued count',
	add: 'Add',
	shift: 'Shift',
	noResponse: 'No response',
	resign: 'Resign',
	confirm: 'Confirm',
	contactOrders: 'Contact Orders',
	no: 'No.',
	status: 'Status',
	currency: 'Currency',
	product: 'Product',
	market: 'Market',
	createdAt: 'Created at',
	sendMail: 'Send Mail',
	save: 'Save',
	street: 'Street',
	postalCode: 'Postal code',
	city: 'City',
	additionalInfo: 'Info for the courier',
	courierInfo: 'Info for the courier',
	country: 'Country',
	email: 'E-mail',
	countryCode: 'Country code',
	deliveryDate: 'Delivery date',
	comment: 'Comment',
	commentInfo: 'Type comment...',
	basicInfo: 'Basic info',
	items: 'Items',
	send: 'Send',
	phone: 'phone',
	shippingInfo: 'Shipping information',
	goToDashBoard: 'Go to DashBoard',
	variant: 'Variants',
	price: 'Price',
	name: 'Name',
	short: 'Short',
	trash: 'Trash',
	ticket: 'Create ticket',
	createInvoice: 'Create invoice',
	checkProm: 'Check promotion',
	promNotFound: 'A province with a postal code was not found',
	promNotAvaiable: 'Error! Province:',
	promNotAvaiableTitle:
		'We are currently not running a promotional offer for this region. (Sale at a price of 9800 Kč)',
	promAvaialbleTitle: 'Postal code correct!  Province:',
	promAvaiable: 'The customer can take advantage of the promotional offer',
	informed: 'Informed',
	phone_number_for_courier: 'Phone for the courier',
	courier: 'Courier',
	timeline: 'Timeline',
	invoice_status: 'Invoice status by color',
	mail_history: 'Mail history',
	shipping_history: 'Shipping history',
	suspicion: 'Suspicion',
	suspect: 'Suspect',
	addOrder: 'Add Order',
	orders: 'Orders',
	variants: 'Variants',
	shippingStatus: 'Shipping status',
	actions: 'Actions',
	takeNewContact: ' Take new Contact',
	create: 'Create',
	contactInfo: 'Contact Info',
	order: 'Order',
	suggested_delivery_date: 'Suggested delivery date',
	chooseOrderItem: 'Choose order item',
	chosenOrderItems: 'Chosen order items',
	nothingToSee: 'There is no products in this order',
	shipping: 'Shipping',
	surname: 'Surname',
	topic: 'Topic',
	ticket_question: 'Ticket question',
	receiver: 'Receiver',
	file: 'File',
	create_ticket: 'Create ticket',
	type_message: 'Type message',
	Dashboard: 'Dashboard',
	My_statistics: 'My statistics',
	My_orders: 'My orders',
	My_salary: 'My salary',
	Settings: 'Settings',
	CUR_SCORE: 'CUR score',
	Listening_Scoring: 'Listening Scoring',
	Last_Result: 'Last result',
	Logout: 'Logout',
	Hours: 'Hours',
	Tickets: 'Tickets',
	FAQ: 'FAQ',
	Listening: 'Listening',
	phoneNumberForCourier: 'Phone number for courier',
	you: 'You',
	package_delivery: 'Package Delivery',
	sum_up: 'Sum up',
	updated: 'updated',
	sayHiToUser: 'Hello',
	ImportNewContact: 'Import new contact',
	CurrentContact: 'Import current contacts',
	Grade: 'Grade',
	CurrentMonth: moment().startOf('month').format('MMMM'),
	LastMonth: moment().subtract(1, 'month').startOf('month').format('MMMM'),
	CreateContact: 'Create contact',
	ImportInboundContact: 'Import incomming contact',
	PlaceholderInbound: "type phone number without '+' ",
	Your_shifts: 'Your shifts',
	Your_emails: 'Your emails',
	shift_date: 'shift date',
	full_name: 'full name',
	number: 'number',
	should_call: 'should call',
	mails: 'Mails',
	shifts: 'Shifts',
	sent_date: 'sent date',
	downloaded_contact_text: 'Please end your contact',
	inboundCallText: 'Import current inbound contact',
	blackboard: 'Informations',
	yourTickets: 'Your Tickets',
	tickets: 'Tickets',
	message: 'Message',
	old_tickets: 'Old tickets',
	value: 'Value',
	sal_for_one: 'Salary for one',
	text3: 'Confirmed orders',
	text4: 'Delivered orders',
	text5: 'Effective salary',
	text6: 'Total',
	consultant: 'Consultant',
	month: 'Month',
	hours_payment: 'Hours payment',
	effective_payment: 'Effective payment',
	payment: 'Payment',
	equalization: 'Equalization',
	manager_payment_summarize: 'Manager payment summarize',
	desctiption: `CUR - Contact Utilization Rate - stands for how effectively you sell the
	rates in relation to the number of contacts downloaded. Your final rating
	is calculated on putting a special algorithm. Based on this
	rating the contacts you receive are adjusted.`,
	textOne: 'Dequeue number',
	textTwo: 'Downloaded',
	textThree: 'Cost',
	textFour: 'Delivered',
	textFive: 'Delivered sum',
	textFour_new: 'Confrimed',
	textFive_new: 'Confrimed sum',
	textSix: 'Grade',
	connectingToServer: 'Connecting to server ...',
	connectingToCalling: 'Connecting to calling ...',
	type: 'Type',
	updated_by: 'Last updated at',
	last_Updated_at: 'Last updated at',
	last_message: 'Last message',
	no_answer: 'User that you are trying to call is not avaiable',
	calling: 'Calling... ',
	errorText:
		'Please allow the microphone or check if the microphone works on your computer!!!',
	contact: 'Contact',
	shipping_status: 'Shipping status',
	edit: 'Edit',
	suggested_delivery: 'Suggested delivery',
	changeDate: 'Change date',
	action: 'Action',
	confirmed: 'Confirmed',
	resigned: 'Resigned',
	general: 'General',
	todays_contacts: "Today's contacts",
	todays_mails: "Today's mails",
	donwloaded_at: 'Downloaded at',
	decision: 'Decision',
	sent_at: 'Sent at',
	there_is_no_client: 'There is no client like that',
	new_ticket: 'There is NEW TICKET!',
	curier: 'Courier',
	changeCurier: 'Change courier',
	Avg: 'Avg',
	today: 'today',
	'last 7 days grade': 'last 7 days grade',
	'last 7 days results': 'last 7 days results',
	Downloaded: 'Downloaded',
	start_calling: 'Start calling',
	team_stats: 'Team stats',
	username: 'Username',
	confirmed_orders: 'Confirmed orders',
	calls: 'Calls',
	thereIsNoDraftForYou: 'There is no draft for you :(((',
	title: 'Employee Resources',
	description: `Explore our Employee Resources for essential HR documents, including 
	vacation requests and HR policies, to simplify employee management and improve HR efficiency.`,
	facts_questions: 'FACTS & QUESTIONS',
	category: 'Category',
	facts_description:
		"In this section, you'll find information and answers to frequently asked questions about our service/product",
	Month: 'Month',
	'No.': 'No.',
	Date: 'Date',
	'Attendance at work': 'Attendance at work',
	'Break during the day': 'Break during the day',
	'Excused unpaid leave': 'Excused unpaid leave',
	'Manager hours': 'Manager hours',
	'Documents status': 'Documents status',
	Actions: 'Actions',
	'Absence without excuse': 'Absence without excuse',
	hours_thead_one:
		'You have to fill cells in "reality working hours" columns at the end of each workday',
	hours_thead_two: 'Markings',
	hours_thead_three: 'Description of the markings',
	hours_thead_four: 'Counted as',
	hours_thead_five: 'Hours for day',
	one_description: `Each cell has to filled with character "1" or remain empty.
		"1" next to "8:30" means 8:30 to 9:00, "1" next to "19:30" means 19:30 to 20:00`,
	two_description: `If you have a leave of absence, sick leave, leave on request enter the corresponding designation "h", "s", "u", "sl".
		Otherwise it will be marked as an unexcused absence. Excused absences - DOCUMENTS REQUIRED.`,
	three_description: 'If You want to take a break during the day. "/"',
	four_description: 'If You want to take an excused unpaid leave. "u"',
	five_description:
		'The number of working hours includes everything except unexcused absences. "n"',
	six_description: 'If you are performing your managerial duties.:',
	one_short_description: 'holiday leave (excused absences)	',
	two_short_description: 'sick leave (excused absences)',
	three_short_description: 'leave on request (excused absences)',
	four_short_description: 'Break during the day',
	five_short_description: 'Excused unpaid leave',
	six_short_description: 'Absence without excuse (unexcused absences)',
	seven_short_description: 'Managerial time',
	Save: 'Save',
	Total: 'Total',
	Monday: 'Monday',
	Tuesday: 'Tuesday',
	Wednesday: 'Wednesday',
	Thursday: 'Thursday',
	Friday: 'Friday',
	Saturday: 'Saturday',
	lastMonth: 'Last month',
	currentMonth: 'Current month',
	Documents: 'Documents',
	'Add documents': 'Add documents',
	'Add pdf': 'Add pdf',
	'Add file type': 'Add file type',
	From: 'From',
	To: 'To',
	'Leave your comment': 'Leave your comment',
	Upload: 'Upload',
	'Leave on request': 'Leave on request',
	Holiday: 'Holiday',
	'Sick leave': 'Sick leave',
	'Special leave': 'Special leave',
	'Vacation days left': 'Vacation days left',
	Type: 'Type',
	Product: 'Product',
	Action: 'Action',
	Number: 'Number',
	'Type without country code': 'Type without country code',
	start: 'From',
	end: 'To',
	your_informations: 'Your informations',
	your_settings: 'Your settings',
	delivered_commision: 'Delivered commision',
	additional_item_commision: 'Additional item commision',
	hourly_rate: 'Hourly rate',
	team_leader: 'Team leader',
	hourly_manager_rate: 'Hourly Manager rate',
	manager_percent: 'Manager percent',
	rest_profile_info:
		'If some information is unclear or incorrect, please contact your Manager.',
	noData: 'There is no data',
	currnetMonthFrom: moment().startOf('month').format('DD MMMM YYYY'),
	currnetMonthTo: moment().endOf('month').format('DD MMMM YYYY'),
	lastMonthFrom: moment()
		.subtract(1, 'month')
		.startOf('month')
		.format('DD MMMM YYYY'),
	lastMonthTo: moment()
		.subtract(1, 'month')
		.endOf('month')
		.format('DD MMMM YYYY'),
	hours: 'Hours',
	effective_salary: 'Effective salary',
	bonus: 'Bonus',
	gamification_bonus: 'Gamification bonus',
	payout_slip: 'Payout Slip',
	special_klaudia_info: `CC Managers check the hours for the previous month up to the first 3 - 4 working days at the beginning of the following month. 
	Only after this time will you see the final hours for the previous month in your bonus statement.`,
	'Download from': 'Download from',
	'Download to': 'Download to',
	'Download Consultants': 'Download Consultants',
	'Add All': 'Add All',
	'No.': 'No.',
	Consultant: 'Consultant',
	'Phone number': 'Phone number',
	Start: 'Start',
	End: 'End',
	Status: 'Status',
	Type: 'Type',
};
