import { getAllPosibleFiledsToFilter } from '@/API/repositories/order';
import { Colors } from '@/common/colors/colors';
import Input from '@/common/components/Input';
import SelectInput from '@/common/components/SelectInput';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { SubTitle } from '@/common/styles/Mails';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DATE_TYPE } from '../../utils/constants';

const BorderLine = styled.div`
	width: 100%;
	background-color: ${Colors.lightlightBlue};
	height: 1px;
`;

const ColorTitle = styled.h5`
	color: ${Colors.darkBlue};
`;

const OptionWrapper = styled.div`
	margin-top: 20px;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
`;

const ARITHMETIC_OPTIONS = [
	{
		label: 'equal',
		value: '$eq',
	},
	{
		label: 'lesser',
		value: '$lt',
	},
	{
		label: 'lesser or equal',
		value: '$lte',
	},
	{
		label: 'grater',
		value: '$gt',
	},
	{
		label: 'grater or equal',
		value: '$gte',
	},
];

const TIME_UNIT_OPTIONS = [
	{
		label: 'Hours',
		value: 'hours',
	},
	{
		label: 'Days',
		value: 'days',
	},
];

const FilterOptionFinderString = ({ fieldData, filters, dispatchFilters }) => {
	const handleOnChange = (value) => {
		if (value.length > 0) {
			return dispatchFilters({
				type: 'add',
				key: fieldData.label,
				value: value,
				type_field: 'String',
			});
		}
		return dispatchFilters({
			type: 'remove',
			key: fieldData.label,
			value: value,
		});
	};

	const handleOnChangeEnum = (value, site) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			value: {
				...(filters[fieldData.label]?.value || {}),
				[site]: value.map((v) => v.value),
			},
			type_field: 'String',
			enumValues: fieldData.value.enumValues,
		});
	};

	if (fieldData.value.enumValues) {
		const options = fieldData.value.enumValues.map((option) => {
			return { label: option, value: option };
		});
		const current = filters[fieldData.label]?.value;

		return (
			<OptionWrapper>
				<ColorTitle>{fieldData.label}:</ColorTitle>
				<BorderLine />
				<div>IN ARR</div>
				<SelectInput
					showLabel={false}
					options={options}
					selected={options.filter((option) =>
						current?.in?.includes(option.value)
					)}
					setSelected={(e) => handleOnChangeEnum(e, 'in')}
					multiple={true}
					selectWidth={500}
				/>
				<div>NOT IN ARR</div>
				<SelectInput
					showLabel={false}
					options={options}
					selected={options.filter((option) =>
						current?.nin?.includes(option.value)
					)}
					setSelected={(e) => handleOnChangeEnum(e, 'nin')}
					multiple={true}
					selectWidth={500}
				/>
			</OptionWrapper>
		);
	}

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<Input
				showLabel={false}
				value={filters[fieldData.label]?.value}
				onChange={(e) => handleOnChange(e.target.value)}
			/>
		</OptionWrapper>
	);
};

const FilterOptionFinderNumber = ({ fieldData, filters, dispatchFilters }) => {
	const handleOnChange = (value) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			value: value,
			type_field: 'Number',
			arithmetic: filters[fieldData.label]?.arithmetic,
		});
	};

	const handleOnChangeArithmetic = (value) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			arithmetic: value,
			value: filters[fieldData.label]?.value,
			type_field: 'Number',
		});
	};

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<SelectInput
				options={ARITHMETIC_OPTIONS}
				showLabel={false}
				selected={ARITHMETIC_OPTIONS.find(
					(e) => e.value === filters[fieldData.label]?.arithmetic
				)}
				onChange={(e) => handleOnChangeArithmetic(e)}
			/>
			<Input
				showLabel={false}
				type='number'
				value={filters[fieldData.label]?.value}
				onChange={(e) => handleOnChange(e.target.value)}
			/>
		</OptionWrapper>
	);
};

const FilterOptionFinderDate = ({ fieldData, filters, dispatchFilters }) => {
	const fieldFilters = filters[fieldData.label];
	const [fromType, setFromType] = useState(
		fieldFilters?.dateFrom?.unit ? DATE_TYPE.RANGE : DATE_TYPE.FIXED
	);
	const [toType, setToType] = useState(
		fieldFilters?.dateTo?.unit ? DATE_TYPE.RANGE : DATE_TYPE.FIXED
	);

	const handleOnChangeDateFrom = (value, fieldId) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Date',
			dateFrom:
				fromType === DATE_TYPE.FIXED
					? value
					: {
							...fieldFilters?.dateFrom,
							[fieldId]: value,
					  },
			dateTo: fieldFilters?.dateTo,
		});
	};

	const handleOnChangeDateTo = (value, fieldId) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Date',
			dateFrom: fieldFilters?.dateFrom,
			dateTo:
				toType === DATE_TYPE.FIXED
					? value
					: {
							...fieldFilters?.dateTo,
							[fieldId]: value,
					  },
		});
	};

	const handleDataTypeChange = (dataType, fieldId) => {
		if (fieldId === 'dateFrom') setFromType(dataType);
		else if (fieldId === 'dateTo') setToType(dataType);

		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Date',
			dateFrom: fieldId === 'dateFrom' ? undefined : fieldFilters?.dateFrom,
			dateTo: fieldId === 'dateTo' ? undefined : fieldFilters?.dateTo,
		});
	};

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h5
						style={{
							width: '40px',
							color: Colors.darkBlue,
							marginLeft: '10px',
							marginRight: '20px',
						}}
					>
						From:
					</h5>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginInline: '10px',
						}}
					>
						<label>
							<input
								type='radio'
								name={`${fieldData.label}_date-from-type`}
								value='fixed'
								style={{ marginRight: '5px' }}
								checked={fromType === DATE_TYPE.FIXED}
								onChange={() =>
									handleDataTypeChange(DATE_TYPE.FIXED, 'dateFrom')
								}
							/>
							fixed
						</label>
						<label>
							<input
								type='radio'
								name={`${fieldData.label}_date-from-type`}
								value='range'
								style={{ marginRight: '5px' }}
								checked={fromType === DATE_TYPE.RANGE}
								onChange={() =>
									handleDataTypeChange(DATE_TYPE.RANGE, 'dateFrom')
								}
							/>
							range
						</label>
					</div>
					{fromType === DATE_TYPE.RANGE ? (
						<>
							<SelectInput
								options={ARITHMETIC_OPTIONS.filter(option => option.value !== '$eq')}
								showLabel={false}
								selected={ARITHMETIC_OPTIONS.find(
									(option) =>
										option.value === fieldFilters?.dateFrom?.comparisonOperator
								)}
								setSelected={() => {}}
								onChange={(e) =>
									handleOnChangeDateFrom(e, 'comparisonOperator')
								}
								selectWidth={120}
							/>
							<SelectInput
								options={TIME_UNIT_OPTIONS}
								name='Unit'
								selectWidth={120}
								width={20}
								selected={TIME_UNIT_OPTIONS.find(
									(option) => option.value === fieldFilters?.dateFrom?.unit
								)}
								setSelected={() => {}}
								onChange={(e) => handleOnChangeDateFrom(e, 'unit')}
								color={Colors.darkBlue}
							/>
							<Input
								name='Amount'
								type='number'
								value={fieldFilters?.dateFrom?.amount}
								onChange={(e) =>
									handleOnChangeDateFrom(e.target.value, 'amount')
								}
								width={60}
								inputWidth={80}
								color={Colors.darkBlue}
							/>
						</>
					) : (
						<Input
							showLabel={false}
							type='date'
							value={fieldFilters?.dateFrom}
							onChange={(e) => handleOnChangeDateFrom(e.target.value, 'date')}
							inputWidth={120}
						/>
					)}
				</div>

				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h5
						style={{
							width: '40px',
							color: Colors.darkBlue,
							marginLeft: '10px',
							marginRight: '20px',
						}}
					>
						To:
					</h5>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginInline: '10px',
						}}
					>
						<label>
							<input
								type='radio'
								name={`${fieldData.label}_date-to-type`}
								value='fixed'
								style={{ marginRight: '5px' }}
								checked={toType === DATE_TYPE.FIXED}
								onChange={() => handleDataTypeChange(DATE_TYPE.FIXED, 'dateTo')}
							/>
							fixed
						</label>
						<label>
							<input
								type='radio'
								name={`${fieldData.label}_date-to-type`}
								value='range'
								style={{ marginRight: '5px' }}
								checked={toType === DATE_TYPE.RANGE}
								onChange={() => handleDataTypeChange(DATE_TYPE.RANGE, 'dateTo')}
							/>
							range
						</label>
					</div>
					{toType === DATE_TYPE.RANGE ? (
						<>
							<SelectInput
								options={ARITHMETIC_OPTIONS.filter(option => option.value !== '$eq')}
								showLabel={false}
								selected={ARITHMETIC_OPTIONS.find(
									(option) =>
										option.value === fieldFilters?.dateTo?.comparisonOperator
								)}
								setSelected={() => {}}
								onChange={(e) => handleOnChangeDateTo(e, 'comparisonOperator')}
								selectWidth={120}
							/>
							<SelectInput
								options={TIME_UNIT_OPTIONS}
								name='Unit'
								selected={TIME_UNIT_OPTIONS.find(
									(option) => option.value === fieldFilters?.dateTo?.unit
								)}
								selectWidth={120}
								width={20}
								setSelected={() => {}}
								onChange={(e) => handleOnChangeDateTo(e, 'unit')}
								color={Colors.darkBlue}
							/>
							<Input
								name='Amount'
								type='number'
								value={fieldFilters?.dateTo?.amount}
								onChange={(e) => handleOnChangeDateTo(e.target.value, 'amount')}
								width={60}
								inputWidth={80}
								color={Colors.darkBlue}
							/>
						</>
					) : (
						<Input
							showLabel={false}
							type='date'
							value={fieldFilters?.dateTo}
							onChange={(e) => handleOnChangeDateTo(e.target.value, 'date')}
							inputWidth={120}
						/>
					)}
				</div>
			</div>
		</OptionWrapper>
	);
};

const FilterOptionExists = ({ fieldData, filters, dispatchFilters }) => {
	const handleOnChange = (value) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Exists',
			isTrue: value,
		});
	};

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<Input
				showLabel={false}
				type='checkbox'
				checked={filters[fieldData.label]?.isTrue}
				onChange={(e) => handleOnChange(e.target.checked)}
			/>
		</OptionWrapper>
	);
};

const FilterOptionFinderBool = ({ fieldData, filters, dispatchFilters }) => {
	const handleOnChange = (value) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Bool',
			isTrue: value,
		});
	};

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<Input
				showLabel={false}
				type='checkbox'
				checked={filters[fieldData.label]?.isTrue}
				onChange={(e) => handleOnChange(e.target.checked)}
			/>
		</OptionWrapper>
	);
};

const FilterOptionFinderObjectId = ({
	fieldData,
	filters,
	dispatchFilters,
	markets,
	products,
	consultants,
	queues,
}) => {
	const handleOnChangeEnum = (value) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			value: value.map((v) => v.value),
			type_field: 'Ref',
		});
	};
	let options = [];
	const current = filters[fieldData.label]?.value;

	if (
		fieldData.value.ref === 'Product' ||
		fieldData.label.includes('product')
	) {
		options = products;
	}

	if (fieldData.value.ref === 'Market' || fieldData.label.includes('market')) {
		options = markets;
	}

	if (fieldData.value.ref === 'User' || fieldData.label.includes('by')) {
		options = consultants;
	}

	if (fieldData.value.ref === 'Queue') {
		options = queues;
	}

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<SelectInput
				options={options}
				showLabel={false}
				selected={options.filter((option) => current?.includes(option.value))}
				setSelected={(e) => handleOnChangeEnum(e)}
				multiple={true}
				selectWidth={500}
			/>
		</OptionWrapper>
	);
};

const FilterOptionFinder = ({
	data,
	filters,
	dispatchFilters,
	markets,
	products,
	consultants,
	queues,
}) => {
	const getObjectIdFilterOption = (fieldData) => {
		if (fieldData.value.type === 'Ref') {
			return (
				<FilterOptionFinderObjectId
					fieldData={fieldData}
					filters={filters}
					dispatchFilters={dispatchFilters}
					markets={markets}
					products={products}
					consultants={consultants}
					queues={queues}
				/>
			);
		}

		if (fieldData.value.type === 'Exists') {
			return (
				<FilterOptionExists
					fieldData={fieldData}
					filters={filters}
					dispatchFilters={dispatchFilters}
				/>
			);
		}

		switch (fieldData.value.ref) {
			case 'Market':
			case 'Product':
			case 'User':
			case 'Queue':
				return (
					<FilterOptionFinderObjectId
						fieldData={fieldData}
						filters={filters}
						dispatchFilters={dispatchFilters}
						markets={markets}
						queues={queues}
						products={products}
						consultants={consultants}
					/>
				);
			default:
				return (
					<FilterOptionExists
						fieldData={fieldData}
						filters={filters}
						dispatchFilters={dispatchFilters}
					/>
				);
		}
	};

	return data.map((fieldData) => {
		if (fieldData.value.type === 'String' || fieldData.value.type === 'Enum')
			return (
				<FilterOptionFinderString
					fieldData={fieldData}
					filters={filters}
					dispatchFilters={dispatchFilters}
				/>
			);
		if (fieldData.value.type === 'Date')
			return (
				<FilterOptionFinderDate
					fieldData={fieldData}
					filters={filters}
					dispatchFilters={dispatchFilters}
				/>
			);
		if (fieldData.value.type === 'Number')
			return (
				<FilterOptionFinderNumber
					fieldData={fieldData}
					filters={filters}
					dispatchFilters={dispatchFilters}
				/>
			);
		if (['Boolean', 'Bool'].includes(fieldData.value.type))
			return (
				<FilterOptionFinderBool
					fieldData={fieldData}
					filters={filters}
					dispatchFilters={dispatchFilters}
				/>
			);
		if (
			fieldData.value.type === 'ObjectID' ||
			fieldData.value.type === 'Ref' ||
			fieldData.value.type === 'Exists'
		) {
			return getObjectIdFilterOption(fieldData);
		}
	});
};

const CategoryFilter = ({
	filters,
	dispatchFilters,
	selectedData,
	setSelectedData,
	markets,
	products,
	consultants,
	queues,
}) => {
	const [data, setData] = useState();
	const { makeRequest } = useRequestsContext();

	const getData = async () => {
		const response = await makeRequest(getAllPosibleFiledsToFilter);

		if (response.data) {
			setData(() => [
				...Object.entries(response.data.orderFields).map(([key, value]) => {
					return { label: 'order.' + key, value: value };
				}),
			]);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const handleSelected = (e) => {
		dispatchFilters({ type: 'validate', values: e });
		setSelectedData(e);
	};

	return (
		<div>
			{data && (
				<div>
					<SubTitle>Filters:</SubTitle>
					<SelectInput
						name='Field'
						color={Colors.darkBlue}
						width={30}
						selected={selectedData}
						setSelected={(e) => handleSelected(e)}
						options={data}
						multiple={true}
						selectWidth={840}
					/>
					<FilterOptionFinder
						data={selectedData}
						dispatchFilters={dispatchFilters}
						filters={filters}
						markets={markets}
						products={products}
						queues={queues}
						consultants={consultants}
					/>
				</div>
			)}
		</div>
	);
};

export default CategoryFilter;
