import Input from '@/common/components/Input';
import SelectInput from '@/common/components/SelectInput';
import { Colors } from 'chart.js';
import { useRef, useState } from 'react';
import {
	ARITHMETIC_OPTIONS,
	TIME_UNIT_OPTIONS,
} from '../../../constants/options';
import {
	BorderLine,
	ColorTitle,
	OptionWrapper,
} from '../../../FilterOptonsModal.styled';

export const FilterOptionFinderDate = ({
	fieldData,
	filters,
	dispatchFilters,
}) => {
	const [selectedUnit, setSelectedUnit] = useState(
		TIME_UNIT_OPTIONS.find(
			(unit) =>
				filters[fieldData?.label] && filters[fieldData?.label][unit?.value]
		)
	);
	const amountRef = useRef();

	const handleOnChange = (value, from) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Date',
			from: filters[fieldData.label]?.from,
			to: filters[fieldData.label]?.to,
			[from]: value,
			arithmetic_from: filters[fieldData.label]?.arithmetic_from,
		});
	};

	const handleOnChangeArithmetic = (value, from) => {
		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Date',
			from: filters[fieldData.label]?.from,
			to: filters[fieldData.label]?.to,
			arithmetic_from: filters[fieldData.label]?.arithmetic_from,
			[selectedUnit?.value]: amountRef.current?.value,
			[from]: value,
		});
	};

	const handleOnChangeUnit = (unit) => {
		setSelectedUnit(() =>
			TIME_UNIT_OPTIONS.find((option) => option.value === unit)
		);

		return dispatchFilters({
			type: 'add',
			key: fieldData.label,
			type_field: 'Date',
			from: filters[fieldData.label]?.from,
			to: filters[fieldData.label]?.to,
			arithmetic_from: filters[fieldData.label]?.arithmetic_from,
			[unit]: amountRef.current?.value,
		});
	};

	return (
		<OptionWrapper>
			<ColorTitle>{fieldData.label}:</ColorTitle>
			<BorderLine />
			<div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<SelectInput
						options={ARITHMETIC_OPTIONS}
						showLabel={false}
						selected={ARITHMETIC_OPTIONS.find(
							(e) => e.value === filters[fieldData.label]?.arithmetic_from
						)}
						setSelected={() => {}}
						onChange={(e) => handleOnChangeArithmetic(e, 'arithmetic_from')}
						selectWidth={120}
					/>
					<SelectInput
						options={TIME_UNIT_OPTIONS}
						name='Unit'
						selected={selectedUnit}
						selectWidth={120}
						width={20}
						setSelected={() => {}}
						onChange={(e) => handleOnChangeUnit(e)}
						color={Colors.darkBlue}
					/>
					<Input
						name='Amount'
						type='number'
						inputRef={amountRef}
						disabled={!selectedUnit}
						value={filters[fieldData.label]?.[selectedUnit?.value]}
						onChange={(e) =>
							handleOnChange(e.target.value, selectedUnit?.value)
						}
						width={60}
						inputWidth={80}
						color={Colors.darkBlue}
					/>
					<h5
						style={{
							width: '40px',
							color: Colors.darkBlue,
							margin: '0px 10px',
						}}
					>
						From:
					</h5>
					<Input
						showLabel={false}
						type='date'
						value={filters[fieldData.label]?.from}
						onChange={(e) => handleOnChange(e.target.value, 'from')}
						inputWidth={120}
					/>
					<h5
						style={{
							width: '40px',
							color: Colors.darkBlue,
							margin: '0px 10px',
						}}
					>
						To:
					</h5>
					<Input
						showLabel={false}
						type='date'
						value={filters[fieldData.label]?.to}
						onChange={(e) => handleOnChange(e.target.value, 'to')}
						inputWidth={120}
					/>
				</div>
			</div>
		</OptionWrapper>
	);
};
