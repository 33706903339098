import { useState, useRef } from 'react';
import { useOrderFilterTableContext } from '../../providers/orderFilterTableProvider';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { createFilter, updateFilter } from '@/API/repositories/filters';
import { OPERATION_ACTIONS, OPERATIONS } from './constants/operations';

const useFilterOptionsModal = () => {
	const context = useOrderFilterTableContext();

	const [sharedFor, setSharedFor] = useState(
		context.usersOptions.filter((c) =>
			context.selectedData.filter?.value?.shared_for?.includes(c.value)
		) || []
	);

	const [saved, setSaved] = useState();
	const nameRef = useRef();
	const publicRef = useRef();
	const [showCreate, setShowCreate] = useState();

	const { makeRequest } = useRequestsContext();

	const handleUpdateGroupBy = (payload) => {
		const operation = OPERATIONS.GROUP_BY;

		const isAdd = payload.length > context.selectedData.groupBys.length;

		if (isAdd) {
			const newField = payload.find(
				(field) =>
					!context.selectedData.groupBys.find((f) => f.label === field.label)
			);

			if (newField) {
				context.setGroupBys(payload);
				context.dispatchOperations({
					type: OPERATION_ACTIONS.ADD,
					payload: {
						label: newField.label,
						type: newField.value.type,
						operation,
					},
				});
			}
		}

		if (!isAdd) {
			const fields = context.selectedData.groupBys.filter(
				(field) => !payload.find((f) => f.label === field.label)
			);

			if (fields?.length) {
				fields.forEach((field) => {
					context.dispatchOperations({
						type: OPERATION_ACTIONS.DELETE,
						payload: field,
					});
				});
			}
			context.setGroupBys(payload);
		}
	};

	const handleSaveOrCreateFilter = async (e) => {
		e && e.preventDefault();
		const payload = {};

		payload.data = context.filters;
		payload.sort_by = context.selectedData.sort?.by;
		payload.sort_type = context.selectedData.sort?.order;
		payload.fields = context.selectedData.displayFields;
		payload.shared_for = sharedFor.map((sf) => sf.value);
		payload.operations = context.operations;

		let response;

		if (context.selectedData.filter) {
			response = await makeRequest(
				updateFilter.bind(
					null,
					context.selectedData.filter.value.context.selectedData.filter,
					payload
				)
			);
		} else {
			payload.name = nameRef.current?.value;
			payload.public = publicRef.current?.checked;
			response = await makeRequest(createFilter.bind(null, payload));
		}

		if (response.data) {
			setSaved(() => 'Saved');
			setTimeout(() => {
				setSaved(() => null);
			}, 3000);
			setShowCreate(() => false);
		}
	};

	const handleSelected = (e) => {
		context.dispatchFilters({ type: 'validate', values: e });
		context.setSubfilters(e);
	};

	const handleSelectOperationFileds = (payload) => {
		const isAdd = payload.length > context.selectedData.operationFields.length;

		if (isAdd) {
			const newField = payload.find(
				(field) =>
					!context.selectedData.operationFields.find(
						(f) => f.label === field.label
					)
			);

			if (newField) {
				const operation = OPERATIONS.COUNT;
				context.dispatchOperations({
					type: OPERATION_ACTIONS.ADD,
					payload: {
						label: newField.label,
						type: newField.value.type,
						operation,
					},
				});
				context.setOperationFields(payload);
			}
		}

		if (!isAdd) {
			const fields = context.selectedData.operationFields.filter(
				(field) => !payload.find((f) => f.label === field.label)
			);

			if (fields?.length) {
				fields.forEach((field) => {
					context.dispatchOperations({
						type: OPERATION_ACTIONS.DELETE,
						payload: field,
					});
				});
				context.setOperationFields(payload);
			}
		}
	};

	return {
		...context,
		handleSelected,
		handleUpdateGroupBy,
		handleSelectOperationFileds,
		sharedFor,
		setSharedFor,
		handleSaveOrCreateFilter,
		setShowCreate,
		saved,
		showCreate,
		nameRef,
		publicRef,
	};
};

export default useFilterOptionsModal;
