import Icon from "@/common/components/Icon";
import {
  calcBonus,
  calcConsultantHours,
  calcEqualization,
  calcHoursPayment,
  calcPaymentResult,
  calcTotalSalary,
} from "@/common/functions/consultantPaymentsCalculations";
import { round } from "@/common/functions/round";

export const getRaws = (summarizeData, handleDownloadFile) =>
  summarizeData
    ?.sort(
      (a, b) =>
        new Date(
          b?.consultantResults?._month_working_info?.working_from
        ).getTime() -
        new Date(
          a?.consultantResults?._month_working_info?.working_from
        ).getTime()
    )
    .map((monthData) => {
      const { consultantResults } = monthData;

      if (!consultantResults) {
        return null;
      }

      const currency = consultantResults.data[0].salary_currency;
      const totalSalaryNotFinal = calcTotalSalary(
        consultantResults.data.find((e) => e.is_final === false)?.data,
        consultantResults._month_working_info
      );

      const totalSalaryFinal =
        calcTotalSalary(
          consultantResults.data.find((e) => e.is_final === true)?.data,
          consultantResults._month_working_info
        ) || 0;

      const hoursPayment = calcHoursPayment(consultantResults);
      const paymentResultNotFinal = calcPaymentResult(consultantResults);
      const paymentResulsFinal =
        calcPaymentResult(consultantResults, true) || 0;
      return (
        <>
          <tr>
            <td rowSpan={2}>
              {consultantResults._month_working_info?.for_month}
            </td>
            <td rowSpan={2}>
              {" "}
              {consultantResults.data[0]._consultant_hours.reduce(
                (prev, next) => prev + calcConsultantHours(next),
                0
              )}{" "}
              h
            </td>
            <td rowSpan={2}>
              {hoursPayment} {currency}
            </td>
            <td>
              {totalSalaryNotFinal} {currency}
            </td>
            <td>
              {paymentResultNotFinal} {currency}
            </td>
            <td>
              {round(
                calcBonus(
                  totalSalaryNotFinal,
                  calcHoursPayment(consultantResults)
                ) || 0
              )}{" "}
              {currency}
            </td>
            <td rowSpan={2}>
              {monthData.gamificationBonus || 0} {currency}
            </td>
            <td rowSpan={2}>
              {calcEqualization(consultantResults)} {currency}
            </td>
            <td rowSpan={2}>
              {consultantResults?._month_working_info?.payout_slip && (
                <Icon
                  onClick={() =>
                    handleDownloadFile(
                      consultantResults?._month_working_info.payout_slip
                    )
                  }
                  name="fa fa-file"
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              {totalSalaryFinal} {currency}
            </td>
            <td>
              {paymentResulsFinal} {currency}
            </td>
            <td>
              {" "}
              {round(
                calcBonus(
                  totalSalaryFinal,
                  calcHoursPayment(consultantResults)
                ) || 0
              )}{" "}
              {currency}
            </td>
          </tr>
        </>
      );
    })
    .filter((raw) => raw);
